/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {dashboard, doctor, menuicon04, menuicon16, patients, sidemenu} from './imagepath';
import Scrollbars from "react-custom-scrollbars-2";
import {useAuth} from '../hooks/AuthProvider';
import useCustomNavigation from "../hooks/useCustomNavigation";
import ControlledComponent from "./AccessControl/ControlledComponent";


const Sidebar = (props) => {
  const {userRoles} = useAuth();
  const {currentURL} = useCustomNavigation();
  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, [])


  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
                 onMouseLeave={expandMenu}
                 onMouseOver={expandMenuOpen}
            >
              <ul>
                <li>
                  <Link
                    to={"/dashboard"}
                    className={props?.activeClassName === 'dashboard' ? 'active' : ''}
                  >
                    <span className="menu-side">
                      <img src={dashboard} alt=""/>
                    </span>{" "}
                    <span> Dashboard </span>
                  </Link>
                </li>
                <ControlledComponent allowedRoles={["admin"]}>
                  <li className="submenu">
                    <Link to={currentURL} id="menu-item1" onClick={(e) => {
                      setSidebar('Doctors')
                      handleClick(e, "menu-item1", "menu-items1")
                    }}>
                    <span className="menu-side">
                      <img src={doctor} alt=""/>
                    </span>{" "}
                      <span> Staff </span> <span className="menu-arrow"/>
                    </Link>
                    <ul style={{display: 'none'}} className="menu-items1">
                      <li>
                        <Link className={props?.activeClassName === 'doctor-list' ? 'active' : ''}
                              to="/caregivers-list">List</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-doctor' ? 'active' : ''} to="/add-caregiver">
                          Add a Healthcare Provider
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ControlledComponent>
                <ControlledComponent allowedRoles={["patient"]} excludedRoles={["admin"]}>
                  <li>
                    <Link
                      to={"/caregivers-list"}
                      className={props?.activeClassName === 'doctor-list' ? 'active' : ''}
                    >
                      <span className="menu-side">
                        <img src={doctor} alt=""/>
                      </span>{" "}
                      <span>Healthcare Providers</span>
                    </Link>
                  </li>
                </ControlledComponent>
                {
                  userRoles.includes("admin") ?
                    <li className="submenu">
                      <Link to={currentURL} id="menu-item2"
                            onClick={(e) => handleClick(e, "menu-item2", "menu-items2")}>
                    <span className="menu-side">
                      <img src={patients} alt=""/>
                    </span>{" "}
                        <span>Patients </span> <span className="menu-arrow"/>
                      </Link>
                      <ul style={{display: "none"}} className="menu-items2">
                        <li>
                          <Link className={props?.activeClassName === 'patient-list' ? 'active' : ''}
                                to="/patientslist">Patients List</Link>
                        </li>
                        <li>
                          <Link className={props?.activeClassName === 'add-patient' ? 'active' : ''} to="/add-patient">Add
                            Patient</Link>
                        </li>
                        {/*<li>*/}
                        {/*  <Link className={props?.activeClassName === 'patient' ? 'active' : ''} to="/patientsprofile">Patients Profile</Link>*/}
                        {/*</li>*/}
                      </ul>
                    </li> : ""
                }

                <ControlledComponent allowedRoles={["admin", "patient"]}>
                  <li className="submenu">
                    <Link to={currentURL} id="menu-item4" onClick={(e) => handleClick(e, "menu-item4", "menu-items4")}>
                    <span className="menu-side">
                      <img src={menuicon04} alt=""/>
                    </span>{" "}
                      <span> Appointments </span> <span className="menu-arrow"/>
                    </Link>
                    <ul style={{display: "none"}} className="menu-items4">
                      <li>
                        <Link className={props?.activeClassName === 'appointment-list' ? 'active' : ''}
                              to="/appointmentlist">Appointment List</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-appointment' ? 'active' : ''}
                              to="/addappointments">Create Appointment</Link>
                      </li>
                    </ul>
                  </li>
                </ControlledComponent>
                <ControlledComponent allowedRoles={["caregiver"]} excludedRoles={["admin"]}>
                  <li>
                    <Link
                      to={"/appointmentlist"}
                      className={props?.activeClassName === 'appointment-list' ? 'active' : ''}
                    >
                      <span className="menu-side">
                        <img src={menuicon04} alt=""/>
                      </span>{" "}
                      <span>Appointments</span>
                    </Link>
                  </li>
                </ControlledComponent>
                <ControlledComponent allowedRoles={["admin"]}>
                  <li className="submenu">
                    <Link to={currentURL} id="menu-item13"
                          onClick={(e) => handleClick(e, "menu-item13", "menu-items13")}>
                    <span className="menu-side">
                      <img src={sidemenu} alt=""/>
                    </span>{" "}
                      <span> Invoices </span> <span className="menu-arrow"/>
                    </Link>
                    <ul style={{display: "none"}} className="menu-items13">
                      <li>
                        <Link className={props?.activeClassName === 'patient-invoice-list' ? 'active' : ''}
                              to="/invoicelist?type=patient">
                          Patient Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'caregiver-invoice-list' ? 'active' : ''}
                              to="/invoicelist?type=caregiver">Staff Invoices</Link>
                      </li>
                    </ul>
                  </li>
                </ControlledComponent>
                <ControlledComponent allowedRoles={["patient", "caregiver"]} excludedRoles={["admin"]}>
                  <li>
                    <Link
                      to={`/invoicelist?type=${userRoles.includes("patient") ? "patient" : "caregiver"}`}
                      className={props?.activeClassName === 'my-invoice-list' ? 'active' : ''}
                    >
                      <span className="menu-side">
                        <img src={sidemenu} alt=""/>
                      </span>{" "}
                      <span>Invoices</span>
                    </Link>
                  </li>
                </ControlledComponent>

                <li>
                  <Link to="/settings">
                    <span className="menu-side">
                      <img src={menuicon16} alt=""/>
                    </span>{" "}
                    <span>Settings</span>
                  </Link>
                </li>
              </ul>
              {/*<div className="logout-btn">*/}
              {/*  <Link to="/login">*/}
              {/*    <span className="menu-side">*/}
              {/*      <img src={logout} alt="" />*/}
              {/*    </span>{" "}*/}
              {/*    <span>Logout</span>*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}
export default Sidebar
