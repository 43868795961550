import {createContext, useContext, useState} from 'react';
import * as PropTypes from "prop-types";

const NotificationContext = createContext();

export function NotificationProvider(props) {
  let {children} = props;
  const [notification, setNotification] = useState(null);

  /**
   * Send a notification toast to the user
   *
   * @param {string} message The message to be displayed
   * @param type Type of notification. One of: info, warn, error, success
   */
  function addNotification(message, type = "info") {
    if (message === null) {
      setNotification(null);
      return;
    }
    const id = Date.now();
    setNotification({id, message, type});
  }

  return (
    <NotificationContext.Provider value={{notification, addNotification}}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {children: PropTypes.node.isRequired}

export const useNotifications = () => useContext(NotificationContext);
