import React from 'react'
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {deleteData} from "../../../api/deleteData";
import useCustomNavigation from "../../../hooks/useCustomNavigation";
import {useNotifications} from "../../../hooks/NotificationProvider";
import {useAuth} from "../../../hooks/AuthProvider";

/**
 * A component that displays a modal for prompting the user
 * if they are sure they wish to delete the invoice or cancel the operation. Clicking
 * 'delete' will delete the invoice and navigate the user to previous page. Cancelling
 * the operation will only dismiss the modal without any additional navigation.
 *
 * @param invoiceID - the id of the invoice to be deleted
 * @param invoiceType - the type of invoice: can be either "patient" or "caregiver"
 * @returns {React.JSX.Element}
 */
const Delete_Invoices = ({invoiceID, invoiceType} ) => {
  const {reload} = useCustomNavigation();
  const notifications = useNotifications();
  const { token } = useAuth();

  async function deleteInvoice() {
    try {
      await deleteData(`${invoiceType}-invoice/${invoiceID}`, token);
      notifications.addNotification("Invoice successfully deleted", "success");
      reload();
    } catch (err) {
      notifications.addNotification(`Could not delete invoice: ${err.message}`, "error");
    }
  }

  return (
    <div>
      <div className="modal custom-modal modal-bg fade" id="delete_invoices_details" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Invoice</h3>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <Link
                      to={"#"}
                      data-bs-dismiss="modal"
                      className="btn btn-primary paid-continue-btn"
                      onClick={deleteInvoice}
                    >
                      Delete
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to={"#"}
                      data-bs-dismiss="modal"
                      className="btn btn-primary paid-cancel-btn"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Delete_Invoices.propTypes = {
  invoiceID: PropTypes.string.isRequired,
  invoiceType: PropTypes.string.isRequired,
}

export default Delete_Invoices
