// A wrapper around all page content. Includes an area for showing notifications
import * as PropTypes from "prop-types";
import {Component} from "react";
import NotificationBanner from "../Ui_Elements/NotificationBanner";

class ContentWrapper extends Component {
  render() {
    let {children, className} = this.props;
    return (
      <>
        <div className={`content ${className}`}>
          <NotificationBanner />
          {children}
        </div>
      </>
    );
  }
}

// Add prop types
ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
ContentWrapper.propTypes = {children: PropTypes.node.isRequired}

export default ContentWrapper;