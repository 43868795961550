import {DatePicker} from "antd";
import React, {useState} from "react";

const BasicInput = () => {
  const [isClicked, setIsClicked] = useState(false);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setIsClicked(true);
  };

  return (
    <>
        <div className="col-lg-8">
          <div className="card-box">
            <h4 className="card-title">Add a License</h4>
            <form action="#">
              <div className="form-group row">
                <label className="col-form-label col-md-2">
                  Personnel ID
                  <span className="login-danger">*</span>
                </label>
                <div className="col-md-10">
                  <input required type="text" className="form-control"/>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">
                  License Number
                  <span className="login-danger">*</span>
                </label>
                <div className="col-md-10">
                  <input required type="text" className="form-control"/>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">
                  Name of Verifier
                  <span className="login-danger">*</span>
                </label>
                <div className="col-md-10">
                  <input required type="text" className="form-control"/>
                </div>
              </div>

              <div className="form-group local-forms cal-icon row">
                <label>
                  License Verification Date
                  <span className="login-danger">*</span>
                </label>
                <DatePicker
                  className="form-control datetimepicker"
                  onChange={onChange}
                  suffixIcon={null}
                  style={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: isClicked ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                      '&:hover': {
                        borderColor: state.isFocused ? 'none' : 'none',
                      },
                    })
                  }}
                />
              </div>

            </form>
          </div>
        </div>
    </>
  );
};

export default BasicInput;
