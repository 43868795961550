import {avatar} from "../imagepath"

import PropTypes from "prop-types";
import {useState} from "react";

export default function AvatarImage({ imgURL="" }) {
  const [src, setSrc] = useState(imgURL);
  return (
    <img
      className="avatar-img rounded-circle"
      src={src}
      loading="lazy"
      alt="avatar"
      onError={() => setSrc(avatar)}
    />
  );
}

AvatarImage.propTypes = {
  imgURL: PropTypes.string,
}