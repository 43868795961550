import React, {useEffect, useState} from 'react'
import {logo, signature} from '../imagepath'
import {useAuth} from "../../hooks/AuthProvider";
import {useNotifications} from "../../hooks/NotificationProvider";
import {fetchData, fetchList} from "../../api/fetchData";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import PropTypes from "prop-types";
import Banner from "../Ui_Elements/Banner";

export default function Invoice ({appointmentID, invoiceID, invoiceType}) {
  const {token} = useAuth();
  const notifications = useNotifications();
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);

  function renderInvoice() {
    return (
      invoiceData ? <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className="card invoice-info-card">
            <div className="card-body">
              <div className="invoice-item invoice-item-one">
                <div className="row">
                  <div className="col-md-6">
                    <div className="invoice-logo">
                      <img src={logo} alt="logo"/>
                    </div>
                    <div className="invoice-head">
                      <h2>Invoice</h2>
                      <p>Invoice Number : {invoiceData.invoice_number}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="invoice-info">
                      <strong className="customer-text-one">Invoice From:</strong>
                      <h6 className="invoice-name">E-tiba Home HealthCare</h6>
                      <p className="invoice-details">
                        +254783119121 <br/>
                        P.O.Box 1681-00620, <br/>
                        Kasarani, D.O. Office, Mwiki Rd - Kenya.<br/>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Invoice Item */}
              <div className="invoice-item invoice-item-two">
                <div className="row">
                  <div className="col-md-6">
                    <div className="invoice-info">
                      <strong className="customer-text-one">Billed to:</strong>
                      <h6 className="invoice-name">{invoiceData.addressed_to}</h6>
                      <p className="invoice-details invoice-details-two">
                        Tel: 9087484288 <br/>
                        Address: line 1, <br/>
                        Zip code ,City - Country
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="invoice-info invoice-info2">
                      <strong className="customer-text-one">Invoice Status</strong>
                      <p className="customer-text">
                        {invoiceData.invoice_status === "Paid" && (
                          <span className="badge bg-success-light">
                            {invoiceData.invoice_status}
                        </span>
                        )}
                        {invoiceData.invoice_status === "Overdue" && (
                          <span className="badge bg-danger-light">
                            {invoiceData.invoice_status}
                        </span>
                        )}
                        {invoiceData.invoice_status === "Cancelled" && (
                          <span className="badge bg-primary-light">
                            {invoiceData.invoice_status}
                        </span>
                        )}
                        {invoiceData.invoice_status === "Pending" && (
                          <span className="badge bg-primary-light">
                            {invoiceData.invoice_status}
                        </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Invoice Item */}
              {/* Invoice Item */}
              <div className="invoice-issues-box">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="invoice-issues-date">
                      <p>Issue Date : {invoiceData.invoice_date}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="invoice-issues-date">
                      <p>Due Date : {invoiceData.due_date}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="invoice-issues-date">
                      <p>Due Amount : Ksh.{invoiceData.total_amount.toLocaleString() || "XXX"} </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Invoice Item */}
              {/* Invoice Item */}
              <div className="invoice-item invoice-table-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="invoice-table table table-center mb-0">
                        <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Item Description</th>
                          <th>Amount (Ksh)</th>
                          <th>Discount (Ksh)</th>
                          <th className="text-end">Total (VAT Inc.)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          invoiceData.invoice_items?.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{item.item_name}</td>
                                <td>{item.item_description}</td>
                                <td>{Number(item.amount).toLocaleString()}</td>
                                <td>{Number(item.discount_amount).toLocaleString()}</td>
                                <td className="text-end">{Number(item.total_amount).toLocaleString()}</td>
                              </tr>
                            );
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Invoice Item */}
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 col-md-6">
                  {invoiceData.terms ?
                    <div className="invoice-terms">
                      <h6>Notes:</h6>
                      <p className="mb-0">{invoiceData.notes}</p>
                    </div>
                    : ""}
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="invoice-total-card">
                    <div className="invoice-total-box">
                      <div className="invoice-total-inner">
                        <p>Taxable <span>Ksh.{invoiceData.invoice_amount.toLocaleString()}</span></p>
                        <p>Total Tax <span>Ksh.{invoiceData.tax_total.toLocaleString()}</span></p>
                      </div>
                      <div className="invoice-total-footer">
                        <h4>Total Amount <span>Ksh.{invoiceData.total_amount.toLocaleString()}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-sign text-end">
                <img className="img-fluid d-inline-block" src={signature} alt="sign"/>
                <span className="d-block">Loise</span>
              </div>
            </div>
          </div>
        </div>
      </div>
        : <Banner type="warning" dismissible={false} text="No invoice is available for the given appointment"/>
    );
  }

  // Check if invoice ID has been specified and then fetch the data
  useEffect(() => {
    if (!invoiceID) {
      return;
    }

    async function fetchInvoiceData() {
      if (!invoiceType) {
        notifications.addNotification("The invoice type has not specified", "error");
        return;
      }
      if (!appointmentID && !invoiceID) {
        notifications.addNotification("The invoice ID has not specified", "error");
        return;
      }

      try {
        let apiData;
        setLoading(true);
        if (invoiceType === 'patient') {
          if (appointmentID) {
            apiData = await fetchList(`patient-invoice?appointment_schedule_id=${appointmentID}`, token);
            apiData = apiData.data.length > 0 ? apiData.data[0] : null;
          } else {
            apiData = await fetchData(`patient-invoice/${invoiceID}`, token);
          }
        } else if (invoiceType === 'caregiver') {
          if (appointmentID) {
            apiData = await fetchList(`caregiver-invoice?appointment_schedule_id=${appointmentID}`, token);
            apiData = apiData.data.length > 0 ? apiData.data[0] : null;
          } else {
            apiData = await fetchData(`caregiver-invoice/${invoiceID}`, token);
          }
        } else {
          notifications.addNotification(`Invoice type "${invoiceType}" is invalid`, "error");
          // navigate("/");
          return;
        }
        setInvoiceData(apiData);

      } catch (error) {
        notifications.addNotification(error.message);
        // navigate("/");
      } finally {
        setLoading(false);
      }
    }

    fetchInvoiceData();
  }, [invoiceID]);

  return (
    <>
      {
        loading ? <SpinAnimation/> : renderInvoice()
      }
    </>
  )
}

Invoice.propTypes = {
  invoiceID: PropTypes.string,
  invoiceType: PropTypes.string,
  appointmentID: PropTypes.string,
}
