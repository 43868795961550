import React, {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/AuthProvider";
// import FeatherIcon from "feather-icons-react";
import { login02, logo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";

import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import ContentWrapper from "../../Dashboard/ContentWrapper";

// import ReactPasswordToggleIcon from 'react-password-toggle-icon';



const Login = () => {

  const auth = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await auth.loginAction({email, password});
    } catch (err) {
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };
  // let inputRef = useRef();
  // const showIcon = () => <i className="feather feather-eye" aria-hidden="true">
  //   <FeatherIcon icon="eye" />
  // </i>;
  // const hideIcon = () => <i className="feather feather-eye-slash" aria-hidden="true">
  //   <FeatherIcon icon="eye-off" />
  // </i>
  useEffect(() => {
    if (user) {
      // TODO: if user is not found but token exists, retrieve the user details from
      //  the API using the token. If the token is not valid, remove it from localstorage
      //  and prompt the user to log in.
      navigate("/dashboard");
    }
  }, []);
  return (
    <>

      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <ContentWrapper className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="#"
                  />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/#">
                          <img src={logo} alt="#" width={120} height={30}/>
                        </Link>
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            required
                            // value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                          type={passwordVisible ? 'password' : ''}
                          className="form-control pass-input"
                          // value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/reset-password">Forgot Password?</Link>
                        </div>
                        <div className="form-group login-btn">
                          {
                            loading ? <SpinAnimation/> :
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Login
                              </button>
                          }
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                        Need an account? <Link to="/signup">Sign Up</Link>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Login;
