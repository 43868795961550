import {useContext, createContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../settings";
import {postData} from "../api/postData";

const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
  const storedUser = localStorage.getItem("user");
  const _user = storedUser ? JSON.parse(storedUser) : null;
  const _roles = _user?.roles?.map((role) => role.name) || [];

  const [user, setUser] = useState(_user);
  const [userRoles, setUserRoles] = useState(_roles);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("token");
  //   const storedUser = localStorage.getItem("user");
  //   console.log("useAuth usefeect")
  //
  //   if (storedToken && storedUser) {
  //     setToken(storedToken);
  //     const parsedUser = JSON.parse(storedUser);
  //     setUser(parsedUser);
  //     const _roles = [];
  //     parsedUser.roles?.map((role) => {
  //       _roles.push(role.name);
  //     });
  //     setUserRoles(_roles);
  //   }
  // }, []);

  const loginAction = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const res = await response.json();

        if (res.data) {
          if (res.data.status === false) {
            alert("Invalid login credentials");
            return;
          }

          setUser(res.data.user);
          setToken(res.data.token);
          setUserRoles(res.data.user?.roles?.map((role) => role.name) || []);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));  // Store user as JSON string
          navigate("dashboard");
        } else {
          alert("Unexpected response structure");
        }
      } else {
        const text = await response.text();
        alert(`Response is not JSON: ${text}`);
      }
    } catch (err) {
      console.log(err.message);
      throw new Error(err.message);
    }
  };

  const logOut = async () => {
    try {
      await postData("logout", token, {});
    } catch (e) {
      throw new Error(`Error logging in: ${e.message}`);
    } finally {
      setUser(null);
      setToken("");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
    }
  };

  return (
    <AuthContext.Provider value={{ token, user, userRoles, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
