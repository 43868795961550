import 'react-toastify/dist/ReactToastify.css';

import {useNotifications} from "../../hooks/NotificationProvider";
import { ToastContainer, toast } from 'react-toastify';
import {useEffect} from "react";

// Displays toast notifications to the user.
export default function NotificationBanner() {
  const {notification, addNotification} = useNotifications();

  useEffect(() => {
    if (notification) {
      const {message, type} = notification;
      const options = {
        type,
      }
      toast(message, options);
      // Clear the notification after showing it
      addNotification(null);
    }
  }, [notification])
  return (
    <ToastContainer
      autoClose={5000}
      limit={5}
      newestOnTop={true}
      position="top-right"
      closeButton={true}>
      closeOnClick={true}
      stacked={true}
      {/*className={"row col-sm-12"}*/}
    </ToastContainer>
  );
}