/* eslint-disable no-unused-vars */
import React from "react";
import {Link} from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import {morning_img_03} from "../../imagepath";

import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useAuth} from "../../../hooks/AuthProvider";
import dayjs from "dayjs";
import AppointmentsCalendar from "../../appointments/AppointmentsCalendar";

const Patient_Dashboard = () => {
  const {user} = useAuth();


  return (
    <>
      <div className="good-morning-blk">
        <div className="row">
          <div className="col-md-6">
            <div className="morning-user">
              <h2>
                Hello, <span>{user.name}</span>
              </h2>
            </div>
          </div>
          <div className="col-md-6 position-blk">
            <div className="morning-img">
              <img src={morning_img_03} alt="#"/>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <AppointmentsCalendar role={"patient"} userID={user.id} />
      </div>
    </>
  );
};

export default Patient_Dashboard;
