import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/pages/login";
import Logout from "./components/pages/logout";
// import config from "config";

//For Settings...
// import Settings from "./components/settings/Settings";
import Localization from "./components/settings/Localization";
import Paymentsetting from "./components/settings/Paymentsetting";
import Settingsemail from "./components/settings/Settingsemail";
import Settingssocialmedia from "./components/settings/Settingssocialmedia";
import Settingssociallinks from "./components/settings/Settingssociallinks";
import Settingsseo from "./components/settings/Settingsseo";
import SettingsThem from "./components/settings/SettingsThem";
import SettingsChangePassword from "./components/settings/SettingsChangePassword";
import SettingsOthers from "./components/settings/SettingsOthers";

//Doctor
import DoctorList from "./components/doctor/DoctorList";
import AddDoctor from "./components/doctor/AddDoctor";
import EditDoctor from "./components/doctor/EditDoctor";
import DoctorProfile from "./components/doctor/DoctorProfile";
//Patients...
import PatientsList from "./components/patients/PatientsList";
import AddPatients from "./components/patients/AddPatients";
import EditPatients from "./components/patients/EditPatients";
import PatientsProfile from "./components/patients/PatientsProfile";
import AppointmentList2 from "./components/appointments/AppointmentList2";
import AddAppointments from "./components/appointments/AddAppointments";

import ProvidentFund from "./components/accounts/ProvidentFund";
import ForgotPassword from "./components/pages/login/ForgotPassword";
import Signup from "./components/pages/login/Signup";
// import Invoice from "./components/accounts/Invoice";
import Create_Invoice from "./components/accounts/Create_Invoice";
import Add_Payment from "./components/accounts/Add_Payment";
import Add_Expense from "./components/accounts/Add_Expense";
import Taxes from "./components/accounts/Taxes";
import Add_Tax from "./components/accounts/Add_Tax";

import Chat from "./components/Chat/Chat";

import Add_ProviderFund from "./components/accounts/Add_ProviderFund";
import ExpensesReport from "./components/ExpenseReport/Expenses/ExpensesReport";
import AddExpenses from "./components/ExpenseReport/Expenses/AddExpenses";
import Invoice_Report from "./components/ExpenseReport/Invoice-report/Invoice_Report";
import OverDue from "./components/Invoice/Invoice-List/Overdue-Invoice/OverDue";
import InvoiceList from "./components/Invoice/Invoice-List/InvoiceList";
import Paid_Invoice from "./components/Invoice/Invoice-List/Paid-Invoice/Paid_Invoice";
import Draft_Invoice from "./components/Invoice/Invoice-List/Draft_Invoice/Draft_Invoice";
import Recurring_Invoice from "./components/Invoice/Invoice-List/Recurring_Invoice/Recurring_Invoice";
import Cancelled_Invoice from "./components/Invoice/Invoice-List/Cancelled_Invoice/Cancelled_Invoice";
import Invoice_Grid from "./components/Invoice/Invoices_Grid/Invoice_Grid";
import Add_Invoices from "./components/Invoice/Add_Invoices/Add_Invoices";
import Invoice_Details from "./components/Invoice/Invoice_Details/Invoice_Details";
import Invoice_GeneralSettings from "./components/Invoice/Invoice_Settings/General_Settings/Invoice_GeneralSettings";

import LockScreen from "./components/pages/login/LockScreen";
import ChangePassword from "./components/pages/login/ChangePassword";
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";

import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile";
import BlankPage from "./components/pages/login/BlankPage";
import Doctor_Settings from "./components/Dashboard/Doctor_Dashboard/Doctor_Settings";
import Patient_Settings from "./components/patients/Patient_Settings";

import Edit_Provident from "./components/accounts/Edit_Provident";
import Edit_Taxes from "./components/accounts/Edit_Taxes";
import Edit_Expenses from "./components/ExpenseReport/Expenses/Edit_Expenses";
import Edit_Payment from "./components/accounts/Edit_Payment";

import Setting from "./components/settings/Setting";
import GalleryImage from "./components/pages/Gallery/Gallery";
import LicensingForm from "./components/Forms/LicensingForm";
import CertificationForm from "./components/Forms/CertificationForm";
import AddPersonForm from "./components/Forms/AddPerson";
import PatientReportNotes from "./components/Forms/PatientReportNotes";
import PersonnelRatingForm from "./components/Forms/PersonnelRatingForm";

import AuthProvider from "./hooks/AuthProvider";
import {NotificationProvider} from "./hooks/NotificationProvider";
import AppointmentDetails from "./components/appointments/AppointmentDetails";
import DashBoard from "./components/Dashboard/DashBoard";
import AddLicenseOrCertification from "./components/ProfessionalQualifications/AddLicenseOrCertification";
import AddNextOfKin from "./components/patients/AddNextOfKin";

//Accounts
const Approuter = () => {
  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      <BrowserRouter basename="/">
        <AuthProvider>
          <NotificationProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/reset-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/lockscreen" element={<LockScreen />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/error" element={<Error />} />
              <Route path="/server-error" element={<ServerError />} />
              <Route path="/blankpage" element={<BlankPage />} />
              <Route path="/gallery" element={<GalleryImage />} />

              {/* Settings */}
              <Route path="/settings" element={<Setting />} />
              <Route path="/localization" element={<Localization />} />
              <Route path="/paymentsetting" element={<Paymentsetting />} />
              <Route path="/settingsemail" element={<Settingsemail />} />
              <Route
                path="/settingssocialmedia"
                element={<Settingssocialmedia />}
              />
              <Route path="/settingssociallink" element={<Settingssociallinks />} />
              <Route path="/settingsseo" element={<Settingsseo />} />
              <Route path="/settingsthem" element={<SettingsThem />} />
              <Route
                path="/settingschangepassword"
                element={<SettingsChangePassword />}
              />
              <Route path="/settingsothers" element={<SettingsOthers />} />

              {/* Doctor  */}
              <Route path="/caregivers-list" element={<DoctorList />} />
              <Route path="/add-caregiver" element={<AddDoctor />} />
              <Route path="/editdoctor" element={<EditDoctor />} />
              <Route path="/doctorprofile" element={<DoctorProfile />} />
              <Route path="/doctor-setting" element={<Doctor_Settings />} />
              {/* Patients */}
              <Route path="/patientslist" element={<PatientsList />} />
              <Route path="/add-patient" element={<AddPatients />} />
              <Route path="/editpatients" element={<EditPatients />} />
              <Route path="/patientsprofile" element={<PatientsProfile />} />
              <Route path="/patient-settings" element={<Patient_Settings />} />
              {/* Appointments */}
              <Route path="/appointmentlist" element={<AppointmentList2 />} />
              <Route path="/addappointments" element={<AddAppointments />} />
              <Route path="/appointment-details" element={<AppointmentDetails />} />

              {/* Accounts */}
              <Route path="/providentfund" element={<ProvidentFund />} />
              <Route path="/add-providerfund" element={<Add_ProviderFund />} />
              <Route path="/invoicelist" element={<InvoiceList />} />
              <Route path="/createinvoice" element={<Create_Invoice />} />
              <Route path="/addpayment" element={<Add_Payment />} />
              <Route path="/addexpense" element={<Add_Expense />} />
              <Route path="/taxes" element={<Taxes />} />
              <Route path="/edit-taxes" element={<Edit_Taxes />} />
              <Route path="/addtax" element={<Add_Tax />} />
              <Route path="/edit-provident" element={<Edit_Provident />} />
              <Route path="/edit-payment" element={<Edit_Payment />} />

              {/* ExpenseReport */}
              <Route path="/expense-Report" element={<ExpensesReport />} />
              <Route path="/add-expense" element={<AddExpenses />} />
              <Route path="/invoice-report" element={<Invoice_Report />} />
              <Route path="/edit-expenses" element={<Edit_Expenses />} />
              {/* Chat */}
              <Route path="/chat" element={<Chat />} />

              {/* Invoice */}
              <Route path="/invoice-list" element={<InvoiceList />} />
              <Route path="/paid-invoice" element={<Paid_Invoice />} />
              <Route path="/overdue-invoice" element={<OverDue />} />
              <Route path="/draft-invoice" element={<Draft_Invoice />} />
              <Route path="/recurring-invoice" element={<Recurring_Invoice />} />
              <Route path="/cancelled-invoice" element={<Cancelled_Invoice />} />
              <Route path="/invoice-grid" element={<Invoice_Grid />} />
              <Route path="/add-invoice" element={<Add_Invoices />} />
              <Route path="/invoice-details" element={<Invoice_Details />} />
              <Route
                path="/invoice-settings"
                element={<Invoice_GeneralSettings />}
              />

              {/* Forms */}
              <Route path="/licensing-form" element={<LicensingForm />} />
              <Route path="/certification-form" element={<CertificationForm />} />
              <Route path="/add-person" element={<AddPersonForm />} />
              <Route path="/report-notes" element={<PatientReportNotes />} />
              <Route path="/ratings" element={<PersonnelRatingForm />} />

              {/* Dashboard */}
              <Route path="/dashboard" element={<DashBoard />} />

              {/* Licenses and Certifications */}
              <Route path="/add-qualification" element={<AddLicenseOrCertification />} />

              {/* Next Of Kin */}
              <Route path="/add-next-of-kin" element={<AddNextOfKin />} />

            </Routes>
          </NotificationProvider>
        </AuthProvider>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
