import React from 'react'
import Header from '../../Header'
import Sidebar from '../../Sidebar'
import {useLocation} from "react-router-dom"
import ContentWrapper from "../../Dashboard/ContentWrapper";
import Invoice from "../Invoice";
import Banner from "../../Ui_Elements/Banner";

const Invoice_Details = () => {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const queryParams = new URLSearchParams(location.search);
  const invoiceID = queryParams.get('id');
  const invoiceType = queryParams.get('type');


  return (
    <>
      <Header/>
      <
        Sidebar
        id='menu-item13'
        // id1='menu-items13'
        activeClassName='invoice-details'
      />
      <>
        <div className="page-wrapper">
          {/* Page Content */}
          <ContentWrapper className="container-fluid">
            {
              (invoiceID && invoiceType) ? <Invoice invoiceID={invoiceID} invoiceType={invoiceType} /> :
                <Banner
                  text={"Either InvoiceID or InvoiceType has not been specified!"}
                  type={"error"}
                  dismissible={false}
                />
            }

          </ContentWrapper>
          {/* /Page Content */}
        </div>

      </>
    </>
  )
}

export default Invoice_Details
