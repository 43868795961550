import React from "react";
import PropTypes from "prop-types";

export default function Banner({text, type = "info", dismissible = true}) {

  if (!["warning", "error", "info", "success"].includes(type)) {
    type = "info";
  }

  function renderButton () {
    if (dismissible) {
      return (
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
          <span aria-hidden="true"> </span>
        </button>
      );
    } else {
      return ("");
    }
  }


  function renderContent() {
    switch (type) {
      case "success":
        return (
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            {text}
            {renderButton()}
          </div>
        );
      case "error":
        return (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {text}
            {renderButton()}
          </div>
        );
      case "info":
        return(
          <div className="alert alert-info alert-dismissible fade show" role="alert">
            {text}
            {renderButton()}
          </div>
        );
      case "warning":
        return (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            {text}
            {renderButton()}
          </div>
        );
    }
  }

  return (
    renderContent()
  );
}

Banner.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  dismissible: PropTypes.bool
}