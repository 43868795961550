/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {Link, useLocation} from 'react-router-dom';
import ContentWrapper from "../Dashboard/ContentWrapper";
import AddAppointmentForm from "./AddAppointmentForm";

const AddAppointments = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentID = queryParams.get("id");
  const caregiverID = queryParams.get("caregiverID");
  const patientID = queryParams.get("patientID");
  const date = queryParams.get("date");


  return (
    <div>
      <Header/>
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appointment"
      />
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      {appointmentID ? "Edit Appointment" : "Add Appointment"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <AddAppointmentForm patientID={patientID} appointmentID={appointmentID} caregiverID={caregiverID} appointmentDate={date}/>
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>

        </div>
      </>
    </div>
  );
};

export default AddAppointments;
