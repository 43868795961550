import Invoice from "./Invoice";
import PropTypes from "prop-types";

export default function InvoiceModal({invoiceID, appointmentID, invoiceType}) {
  return (
    <div>
      <div className="modal custom-modal modal-bg fade" id="view_invoice_details" role="dialog">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <Invoice invoiceID={invoiceID} appointmentID={appointmentID} invoiceType={invoiceType}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InvoiceModal.propTypes = {
  invoiceID: PropTypes.string.isRequired,
  appointmentID: PropTypes.string.isRequired,
  invoiceType: PropTypes.string.isRequired,
}