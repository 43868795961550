import React, {useState} from "react";
import Select from "react-select";

const PersonnelRatingForm = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState([
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ]);

  return (
    <>
      <div className="col-lg-8">
        <div className="card-box">
          <h4 className="card-title">Feedback for Appointment #123</h4>
          <form action="#">
            <div className="form-group row">
              <label className="col-form-label">
                On a scale of 1-5, how would you rate the <strong>quality</strong> of the service during the appointment?
              </label>
              <div className="col-md-4">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={option}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label">
                Describe your overall experience
              </label>
              <div className="col-md-10">
                        <textarea
                          rows={5}
                          cols={5}
                          className="form-control"
                          placeholder="Enter text here"
                          defaultValue={""}
                        />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label">
                On a scale of 1-5, how would you rate the service you received with regards to <strong>communication</strong>?
              </label>
              <div className="col-md-4">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={option}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label">
                On a scale of 1-5, how would you rate the service you received with regards to <strong>time keeping</strong>?
              </label>
              <div className="col-md-4">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={option}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label">
                Would you recommend the healthcare personnel to others?
              </label>
              <div className="col-md-10">
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="recommendation" value='yes'/> Yes
                  </label>
                </div>
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="recommendation" value='no'/> No
                  </label>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
};

export default PersonnelRatingForm;
