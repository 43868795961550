import React from "react";
import PropTypes from "prop-types";
import InvoiceTable from "./InvoiceTable";
import useFilterBarParams from "../../../hooks/useFilterBarParams";
import FilterBar from "../../Ui_Elements/FilterBar";

export default function InvoiceTableWithFilter(props) {
  const q = useFilterBarParams();

  return (
    <>
      <FilterBar invoiceStatusOptions={["Paid", "Pending", "Overdue", "Cancelled"]}/>
      <
        InvoiceTable
        invoiceStatus={q.invoiceStatus}
        dateFrom={q.dateFrom}
        dateTo={q.dateTo}
        {...props}
      />
    </>
  );
}

InvoiceTableWithFilter.propTypes = {
  props: PropTypes.object,
}