import React from "react";
import AppointmentsTable from "./AppointmentsTable";
import PropTypes from "prop-types";
import FilterBar from "../Ui_Elements/FilterBar";
import useFilterBarParams from "../../hooks/useFilterBarParams";

export default function AppointmentsTableWithFilter(props) {
  const q = useFilterBarParams();

  return (
    <>
      <FilterBar appointmentStatusOptions={["Pending", "Approved", "Declined", "Cancelled"]}/>
      <
        AppointmentsTable
        dateStart={q.dateFrom}
        dateEnd={q.dateTo}
        status={q.appointmentStatus}
        {...props}
      />
    </>
  );
}

AppointmentsTableWithFilter.propTypes = {
  props: PropTypes.object,
}