/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../imagepath";
import Select from "react-select";

const Setting = () => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "California" },
    { value: 3, label: "Tasmania" },
    { value: 4, label: "Auckland" },
    { value: 5, label: "Marlborough" },
  ]);
  const [country, setCountry] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "India" },
    { value: 3, label: "London" },
    { value: 4, label: "USA" },
    { value: 5, label: "French" },
  ]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="setting" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="settings-menu-links">
            <ul className="nav nav-tabs menu-tabs">
              <li className="nav-item active">
                <Link className="nav-link" to="/settingschangepassword">
                  Change Password
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Setting;
