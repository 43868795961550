import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {Link} from 'react-router-dom';
import ContentWrapper from "../Dashboard/ContentWrapper";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import AddLicenseOrCertificationForm from "./AddLicenseOrCertificationForm";


const AddLicenseOrCertification = () => {
  const {queryParams} = useCustomNavigation();
  const caregiverID = queryParams.get("caregiverID");
  const type = queryParams.get("type");
  const id = queryParams.get("id");


  return (
    <div>
      <Header/>
      <Sidebar/>
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/profile">Profile </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      {type ? `Add ${type}` : ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <AddLicenseOrCertificationForm id={id} type={type} caregiverID={caregiverID} />
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>

        </div>
      </>
    </div>
  );
};

export default AddLicenseOrCertification;
