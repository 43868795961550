/* eslint-disable no-unused-vars */
import { favicon } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../../hooks/AuthProvider";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import useErrorNotification from "../../hooks/useErrorNotification";
import {DatePicker} from "antd";
import {putData} from "../../api/putData";
import {postData} from "../../api/postData";
import {useNotifications} from "../../hooks/NotificationProvider";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import {fetchData} from "../../api/fetchData";
import dayjs from "dayjs";

export default function AddLicenseOrCertificationForm({type, id, caregiverID}) {
  const {token} = useAuth();
  const notifications = useNotifications();
  const {goBack} = useCustomNavigation();

  const typeCapitalized = type? `${type.charAt(0).toUpperCase() + type.slice(1)}` : "";
  const {userRoles, user} = useAuth();
  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState("");
  const [loading, setLoading] = useState(false);

  // Form props
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [issuer, setIssuer] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [show, setShow] = useState(true);

  useErrorNotification(error);

  function onIssueDateChange(date, dateString) {
    setIssueDate(dateString);
  }

  function onExpiryDateChange(date, dateString) {
    setExpiryDate(dateString);
  }

  function loadFile (event) {
    // console.log(event,"event");

  }

  async function onSubmit(event) {
    setLoading(true);
    event.preventDefault();

    if (!issueDate) {
      setError("Date of issue must be provided");
      setLoading(false);
      return;
    }
    if (event.target.checkValidity) {
      const formData = {
        health_care_personnel_id: caregiverID,
        expiry_date: expiryDate || null,
        issued_by: issuer || null,
      }
      if (type === "license") {
        formData.license_number = documentID || null;
        formData.license_name = documentTitle || null;
        formData.issued_date = issueDate || null;
      } else if (type === "certification") {
        formData.issue_date = issueDate || null;
        formData.certification_name = documentTitle || null;
      }

      try {
        const suffix = (type === "license" ? "licensing" : "certification");
        if (id) {
          await putData(`caregiver-${suffix}/${id}`, token, formData);
          notifications.addNotification(`${type} successfully updated`, "success");
          goBack();
        } else {
          await postData(`caregiver-${suffix}`, token, formData);
          notifications.addNotification(`${type} successfully added`, "success");
          goBack();
        }
      } catch (err) {
        setError(err.message);
      }
    }

    setLoading(false);
  }

  // Check for errors in the parameters
  useEffect(() => {
    if (!caregiverID) {
      setFatalError("The query parameter 'caregiverID' is required");
    } else if (!type) {
      setFatalError("The query parameter 'type' is required");
    } else if (!["license", "certification"].includes(type)) {
      setFatalError(`Invalid certification type '${type || 'null'}'. Must be either 'license' or 'certification'`);
    } else if (!(userRoles.includes("admin") || user.id === caregiverID)) {
      setFatalError("You do not have enough permission to perform the requested action");
    }
  }, []);

  // Load item data from the API if id has been provided
  useEffect(() => {
    const populateForm = async () => {
      setLoading(true);
      try {
        if (type === "license") {
          const data = await fetchData(`caregiver-licensing/${id}`, token);
          setDocumentTitle(data.license_name || "");
          setDocumentID(data.license_number || "");
          setIssuer(data.issued_by || "");
          setIssueDate(data.issued_date || "");
          setExpiryDate(data.expiry_date || "");
        } else if (type === "certification"){
          const data = await fetchData(`caregiver-certification/${id}`, token);
          setDocumentTitle(data.certification_name || "");
          setIssuer(data.issued_by || "");
          setIssueDate(data.issue_date || "");
          setExpiryDate(data.expiry_date || "");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id && !fatalError) {
      populateForm().then(() => {
        console.log(`${type} loaded successfully`);
      });
    }
  }, [id]);

  return (
    <>
      {
        fatalError ? <Banner text={fatalError} type={"error"} dismissible={false}/> :
          <form onSubmit={onSubmit}>
            {
              loading ? <SpinAnimation/> :
                <div className="row">
                  <div className="col-12">
                    <div className="form-heading">
                      <h4>{typeCapitalized} Details</h4>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Title of {type}<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={documentTitle}
                        required
                        onChange={(e) => setDocumentTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  {
                    type === "license" ?
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            {typeCapitalized} Number<span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={documentID}
                            required
                            onChange={(e) => setDocumentID(e.target.value)}
                          />
                        </div>
                      </div>: null
                  }

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Name of Issuer<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder={"Example: KMPDC"}
                        type="text"
                        value={issuer}
                        required
                        onChange={(e) => setIssuer(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms cal-icon">
                      <label>
                        Date of Issue{" "}
                        <span className="login-danger">*</span>
                      </label>
                      <DatePicker
                        className="form-control datetimepicker"
                        value={issueDate ? dayjs(issueDate, "YYYY-MM-DD") : ""}
                        onChange={onIssueDateChange}
                        suffixIcon={null}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms cal-icon">
                      <label>
                        Expiry Date
                      </label>
                      <DatePicker
                        className="form-control datetimepicker"
                        value={expiryDate ? dayjs(expiryDate, "YYYY-MM-DD") : ""}
                        onChange={onExpiryDateChange}
                        suffixIcon={null}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-top-form ">
                      <label className="local-top">
                        Upload image of {type} <span className="login-danger">*</span>
                      </label>
                      <div className="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onChange={loadFile}
                          className="hide-input"
                        />
                        <label htmlFor="file" className="upload">
                          Choose File
                        </label>
                      </div>
                      <div
                        className="upload-images upload-size"
                        style={{display: show ? "none" : "block"}}
                      >
                        <img src={favicon} alt="Image"/>
                        <Link to="#" className="btn-icon logo-hide-btn">
                          <i
                            className="feather-x-circle"
                            onClick={() => setShow((s) => !s)}
                          >
                            <FeatherIcon icon="x-circle"/>
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="doctor-submit text-end">
                      <button
                        type="submit"
                        className="btn btn-primary submit-form me-2"
                      >
                        Submit
                      </button>
                      <button
                        type={"reset"}
                        onClick={() => goBack()}
                        className="btn btn-primary cancel-form"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
            }
          </form>
      }
    </>
  );
}

AddLicenseOrCertificationForm.propTypes = {
  type: PropTypes.string.isRequired,
  caregiverID: PropTypes.string,
  id: PropTypes.string,
}