import useCustomNavigation from "./useCustomNavigation";
import {useEffect, useState} from "react";

/**
 * A hook that returns the parameters set by the FilterBar component
 * @returns {{dateTo: string, dateFrom: string, status: string}}
 */
export default function useFilterBarParams() {
  const {queryParams} = useCustomNavigation();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");

  useEffect(() => {
    setDateFrom(queryParams.get("dateFrom"));
    setDateTo(queryParams.get("dateTo"));
    setInvoiceStatus(queryParams.get("invoiceStatus"));
    setAppointmentStatus(queryParams.get("appointmentStatus"));
  }, [queryParams]);

  return({dateFrom, dateTo, invoiceStatus, appointmentStatus});
}