/* eslint-disable no-unused-vars */
import Calendar from "react-calendar";
import {blogimg8, noAppointments, plusicon} from "../imagepath";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useNotifications} from "../../hooks/NotificationProvider";
import {useAuth} from "../../hooks/AuthProvider";
import {fetchList} from "../../api/fetchData";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import Banner from "../Ui_Elements/Banner";
import ControlledComponent from "../AccessControl/ControlledComponent";

const today = dayjs().format("YYYY-MM-DD");
const monthStart = dayjs().startOf('month').toISOString();
// `monthEnd` will be the first day of the next month to make the date range
// [monthStart, monthEnd] inclusive of the endpoints.
const monthEnd = dayjs().endOf('month').toISOString();

export default function AppointmentsCalendar({ userID, role }) {

  const {token, userRoles} = useAuth();
  const notifications = useNotifications();
  const [selectedDate, setSelectedDate] = useState(today);
  const [error, setError] = useState("");
  const [selectedMonth, setSelectedMonth] = useState([monthStart, monthEnd]);
  const [loading, setLoading] = useState(false);
  const [monthAppointments, setMonthAppointments] = useState({});
  const [dayAppointments, setDayAppointments] = useState([]);

  // Query string for creating appointments
  let q = "";
  if (role === "patient") {
    q += `patientID=${userID}&`
  } else if (role === "caregiver") {
    q += `caregiverID=${userID}&`
  }

  function onDateChange(value, event) {
    setSelectedDate(dayjs(value).format("YYYY-MM-DD"));
  }

  function onViewChange({ activeStartDate, view }) {
    if (view === "month") {
      const firstDay = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
      const lastDay = new Date(activeStartDate.getFullYear(),activeStartDate.getMonth() + 1);
      setSelectedMonth([
        dayjs(firstDay).format("YYYY-MM-DD"),
        dayjs(lastDay).format("YYYY-MM-DD")
        ]);
    }
  }

  function groupAppointmentsByDate(appointments) {
    return appointments.reduce((acc, appointment) => {
      // Extract the "YYYY-MM-DD" part
      const dateKey = appointment.appointment_start_date.split(" ")[0];
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(appointment); // Add the appointment to the relevant date key
      return acc;
    }, {});
  }

  // Apply styles to dates with appointments
  function tileContent({ date, view }) {
    if (view === 'month') {
      const dt = dayjs(date).format("YYYY-MM-DD");
      if (monthAppointments[dt]) {
        return <div
          style={{backgroundColor: 'blue', borderRadius: '50%', width: '6px', height: '6px', margin: 'auto'}}/>;
      }
    }
  }

  function renderAppointments() {
    if (dayAppointments.length === 0) {
      return (
        <img src={noAppointments} alt="no appointments" width="70%" height="70%" />
      );
    }
    return (
      <>
      <div style={{maxHeight: "50vh", overflow: "auto"}}>
        {
          dayAppointments.map((item) => {
            // Trim the description
            let description = item.service_name || item.appointment_description || "";
            if (description.length > 50) {
              description = description.slice(0, 50) + "...";
            }

            // Get the start and end time
            const startTime = dayjs(item.appointment_start_date).format("h:mm A");
            const endTime = dayjs(item.appointment_end_date).format("h:mm A");

            let statusClassName;
            if (item.appointment_status === "Pending") {
              statusClassName = "status-grey";
            } else if (item.appointment_status === "Approved") {
              statusClassName = "status-green";
            } else if (item.appointment_status === "Declined") {
              statusClassName = "status-red";
            } else if (item.appointment_status === "Cancelled") {
              statusClassName = "status-red";
            } else {
              statusClassName = "status-grey"
            }

            return (
              <div key={item.id} className="treat-box mb-2">
                <div className="user-imgs-blk">
                  <Link to={`/profile?id=${item.health_care_personnel_id}`}>
                    <img src={blogimg8} alt="#"/>
                  </Link>
                  <div className="active-user-detail flex-grow-1">
                    <p>{startTime} - {endTime}</p>
                    <h4>
                      <Link to={`/appointment-details?id=${item.id}`}>
                        {description || "No description"}
                      </Link>
                    </h4>
                    <ControlledComponent allowedRoles={["admin"]}>
                      <Link to={`/profile?id=${item.health_care_personnel_id}`}>
                        <p>
                          <b>{item.health_care_personnel_name}</b> (caregiver)
                        </p>
                      </Link>
                      <Link to={`/profile?id=${item.patient_id}`}>
                        <p>with <b>{item.patient_name}</b> (patient)</p>
                      </Link>
                    </ControlledComponent>

                    <ControlledComponent allowedRoles={["patient"]}>
                      <Link to={`/profile?id=${item.health_care_personnel_id}`}>
                        <p>with <b>{item.health_care_personnel_name}</b> (caregiver)</p>
                      </Link>
                    </ControlledComponent>

                    <ControlledComponent allowedRoles={["caregiver"]} excludedRoles={["admin"]}>
                        <Link to={`/profile?id=${item.patient_id}`}>
                          <p>with <b>{item.patient_name}</b> (patient)</p>
                        </Link>
                    </ControlledComponent>
                  </div>
                </div>
                <div>
                  <Link to="#" className={`custom-badge ${statusClassName}`}>
                    {item.appointment_status || "Undefined Status"}
                  </Link>
                </div>
              </div>
            );
          })
        }
      </div>
      </>
    );
  }


  useEffect(() => {
    const loadAppointments = async () => {
      try {
        setLoading(true);
        setError("");
        let q = `appointment-schedule?appointment_start_date_from=${selectedMonth[0]}&appointment_start_date_to=${selectedMonth[1]}&`;
        if (role === 'patient') {
          q += `patient_id=${userID}&`
        } else if (role === 'caregiver') {
          q += `health_care_personnel_id=${userID}&`
        }
        q += "paginate=0";
        const apiData = await fetchList(q, token, false);
        if (apiData.data.length > 0) {
          setMonthAppointments(groupAppointmentsByDate(apiData.data));
        }
      } catch (err) {
        notifications.addNotification(err.message, "error");
      } finally {
        setLoading(false);
      }
    };

    if (selectedMonth.length === 2) {
      loadAppointments();
    }
  }, [selectedMonth]);

  useEffect(() => {
    setDayAppointments(monthAppointments[selectedDate] || [])
  }, [selectedDate, monthAppointments]);

  return (
    <>
      <div className="row d-flex align-items-center justify-content-center mb-3">
        <div className="col-md-6">
          <h4>Upcoming appointments</h4>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <ControlledComponent allowedRoles={["admin", "patient"]}>
            <div className="add-group">
              <Link to={`/addappointments?date=${selectedDate}&${q}`}
                    className="btn btn-primary add-pluss"
              >
                <img src={plusicon} alt="#"/>
              </Link>
            </div>
            <span className="m-l-5">Create an appointment</span>
          </ControlledComponent>
        </div>
      </div>
      <div className="col-md-6 mb-5">
        <div className="treat-box mb-2">
          <div className="user-imgs-blk">
            <div className="circle-bar circle-bar2">
              <div className="circle-graph2" data-percent="66">
                <div>
                  <Calendar
                    style={{width: "50%"}}
                    onChange={onDateChange}
                    onActiveStartDateChange={onViewChange}
                    value={selectedDate}
                    tileContent={tileContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-5 d-flex justify-content-center">
          {
            loading ? <SpinAnimation /> :
              <>
                {
                  error ? <Banner text={error} dismissible={false}/>
                    : renderAppointments()
                }
              </>
          }
      </div>


    </>
  );
}

AppointmentsCalendar.propTypes = {
  userID: PropTypes.string,
  role: PropTypes.string,
}