/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import Header from '../../Header'
import Sidebar from '../../Sidebar';
import ContentWrapper from "../../Dashboard/ContentWrapper";
import Banner from "../../Ui_Elements/Banner";
import AddInvoiceForm from "./AddInvoiceForm";
import {Link, useLocation} from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Add_Invoices = () => {
  const [error, setError] = useState("");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const appointmentID = queryParams.get('appointmentID');
  const invoiceID = queryParams.get('invoiceID');
  const patientID = queryParams.get('patientID');
  const caregiverID = queryParams.get('caregiverID');
  const invoiceType = queryParams.get('invoiceType')

  // Verify that the correct query parameters have been provided
  useEffect(() => {
    if (!appointmentID) {
      setError("Appointment ID must be specified in the query string");
    } else if (!invoiceType || !["patient", "caregiver"].includes(invoiceType)) {
      setError("Invoice Type must be specified in the query string as either patient/caregiver");
    } else if (!patientID && !caregiverID) {
      setError("Either Patient ID or Caregiver ID must be specified in the query string");
    }
  }, [])



  return (
    <>
      <Header/>
      <Sidebar id='menu-item13' id1='menu-items13' activeClassName='add-invoice'/>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Accounts</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right"/>
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Invoice</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            {
              error ? <Banner type="error" dismissible={false} text={error}/> :
                <AddInvoiceForm
                  appointmentID={appointmentID}
                  invoiceType={invoiceType}
                  invoiceID={invoiceID}
                  patientID={patientID}
                  caregiverID={caregiverID}
                />
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default Add_Invoices
