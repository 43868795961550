/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import Header from '../Header';
import Sidebar from '../Sidebar';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import useErrorNotification from "../../hooks/useErrorNotification";
import ContentWrapper from "../Dashboard/ContentWrapper";
import AppointmentsTableWithFilter from "./AppointmentsTableWithFilter";
import {useAuth} from "../../hooks/AuthProvider";

const AppointmentList2 = () => {

  const {userRoles} = useAuth();
  const [error, setError] = useState("");
  useErrorNotification(error);

  return (
    <>
      <Header/>
      <Sidebar
        id={userRoles.includes("admin") || userRoles.includes("patient") ? 'menu-item4' : ""}
        id1={userRoles.includes("admin") || userRoles.includes("patient") ? 'menu-items4' : ""}
        activeClassName='appointment-list'
      />
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Appointment List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <AppointmentsTableWithFilter />
          </ContentWrapper>
        </div>
      </>


      <>

      </>

    </>

  )
}

export default AppointmentList2;

