/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import React, {useEffect} from "react";
import {
  login02,
  loginicon01,
  loginicon02,
  loginicon03,
  logo,
} from "../../imagepath";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import ContentWrapper from "../../Dashboard/ContentWrapper";
import {useNotifications} from "../../../hooks/NotificationProvider";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import {getRole} from "../../../api/getRole";
import axios from "axios";
import {BASE_URL} from "../../../settings";
import {postData} from "../../../api/postData";
import {useAuth} from "../../../hooks/AuthProvider";

const Signup = () => {

  const { loginAction } = useAuth();
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(true);
  const [password, setPassword] = useState('');
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [passwordVisible1, setPasswordVisible1] = useState(true);
  const [password1, setPassword1] = useState('');
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const [selectedRegType, setSelectedRegType] = useState({value: "patient", label: "Patient"});
  const [role, setRole] = useState("");
  const regTypeOptions = [
    {value: "patient", label: "Patient"},
    {value: "caregiver", label: "Healthcare Provider"},
  ]
  const [selectedGender, setSelectedGender] = useState("");
  const genderOptions = [
    {value: "Male", label: "Male"},
    {value: "Female", label: "Female"},
  ]
  const [surname, setSurname] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  async function register(data) {
    try {
      return await postData("user", "", data);
    } catch (err) {
      notifications.addNotification(err.message, "error");
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    // Verify that passwords match
    if (password !== password1) {
      notifications.addNotification("Error: Passwords do not match", "error");
      return;
    }
    if (!selectedGender) {
      notifications.addNotification("Error: Gender must be specified");
      return;
    }
    try {
     setLoading(true);
     if (e.target.checkValidity()) {
       const data = {
         surname,
         email,
         other_names: otherNames,
         phone_number: mobile,
         role,
         password,
         gender: selectedGender.value,
       };
       const res = await register(data);
       if (res) {
         await loginAction({email, password});
       }
     }
    } catch (e) {
      notifications.addNotification(e.message, "error")
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function _getRole() {
      try {
        const res = await getRole(selectedRegType.value, "");
        setRole(res.id);
      } catch (err) {
        notifications.addNotification(err.message, "error");
      }
    }
    _getRole();
  }, [selectedRegType])

  return (
    <div>
      <div className="main-wrapper login-body">
        <ContentWrapper className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/">
                          <img src={logo} alt="#" width={120} height={30}/>
                        </Link>
                      </div>
                      <h2>Getting Started</h2>
                      {/* Form */}
                      <form onSubmit={onSubmit}>
                        <div className="form-group local-forms">
                          <label>
                            Sign up as a<span className="login-danger">*</span>
                          </label>
                          <Select
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            onChange={setSelectedRegType}
                            value={selectedRegType}
                            options={regTypeOptions}
                            id="search-commodity"
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                '&:hover': {
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                },
                                borderRadius: '10px',
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '250ms',
                                width: '35px',
                                height: '35px',
                              }),
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Surname <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={surname}
                            required
                            onChange={(e) => {
                              setSurname(e.target.value)
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Other Names <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={otherNames}
                            required
                            onChange={(e) => {
                              setOtherNames(e.target.value)
                            }}
                          />
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Gender<span className="login-danger">*</span>
                          </label>
                          <Select
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            onChange={setSelectedGender}
                            value={selectedGender}
                            options={genderOptions}
                            id="search-commodity"
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                '&:hover': {
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                },
                                borderRadius: '10px',
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '250ms',
                                width: '35px',
                                height: '35px',
                              }),
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            value={email}
                            required
                            onChange={(e) => {
                              setEmail(e.target.value)
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Phone Number <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={mobile}
                            required
                            onChange={(e) => {
                              setMobile(e.target.value)
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordVisible ? 'password' : ''}
                            className="form-control pass-input"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                          {passwordVisible ? <EyeOff className="react-feather-custom"/> :
                            <Eye className="react-feather-custom"/>}
                        </span>
                        </div>
                        <div className="form-group">
                          <label>
                            Confirm Password{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordVisible1 ? 'password' : ''}
                            className="form-control pass-input"
                            value={password1}
                            required
                            onChange={(e) => setPassword1(e.target.value)}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility1}
                          >
                          {passwordVisible1 ? <EyeOff className="react-feather-custom"/> :
                            <Eye className="react-feather-custom"/>}
                        </span>
                          {/* <input
                            className="form-control pass-input-confirm"
                            type="password"
                          /> */}
                          <span className="profile-views feather-eye-off confirm-password"/>
                        </div>
                        {/*<div className="forgotpass">*/}
                        {/*  <div className="remember-me">*/}
                        {/*    <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">*/}
                        {/*      {" "}*/}
                        {/*      I agree to the{" "}*/}
                        {/*      <Link to="#">&nbsp; terms of service </Link>*/}
                        {/*      &nbsp; and{" "}*/}
                        {/*      <Link to="#">&nbsp; privacy policy </Link>*/}
                        {/*      <input type="checkbox" name="radio"/>*/}
                        {/*      <span className="checkmark"/>*/}
                        {/*    </label>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="form-group login-btn">
                          {
                            loading ? <SpinAnimation/> :
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Sign up
                              </button>
                          }
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Already have account? <Link to="/login">Login</Link>
                        </p>
                        {/*/!* Social Login *!/*/}
                        {/*<div className="social-login">*/}
                        {/*  <Link to="#">*/}
                        {/*    <img src={loginicon01} alt="#"/>*/}
                        {/*  </Link>*/}
                        {/*  <Link to="#">*/}
                        {/*    <img src={loginicon02} alt="#"/>*/}
                        {/*  </Link>*/}
                        {/*  <Link to="#">*/}
                        {/*    <img src={loginicon03} alt="#"/>*/}
                        {/*  </Link>*/}
                        {/*</div>*/}
                        {/*/!* /Social Login *!/*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </ContentWrapper>
      </div>
    </div>
  );
};

export default Signup;
