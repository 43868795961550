/* eslint-disable no-unused-vars */
import React from "react";
import {
  morning_img_02

} from "../../imagepath";
import {useAuth} from "../../../hooks/AuthProvider";
import AppointmentsCalendar from "../../appointments/AppointmentsCalendar";

const Doctor_Dashboard = () => {
  const {user} = useAuth();

  return (
    <>
      <div className="good-morning-blk">
        <div className="row">
          <div className="col-md-6">
            <div className="morning-user">
              <h2>
                Good Morning, <span>{user.name}</span>
              </h2>
              <p>Have a nice day at work</p>
            </div>
          </div>
          <div className="col-md-6 position-blk">
            <div className="morning-img">
              <img src={morning_img_02} alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <AppointmentsCalendar role={"caregiver"} userID={user.id} />
      </div>
    </>
  );
};

export default Doctor_Dashboard;
