import axios from 'axios';
import {BASE_URL} from "../settings";

/**
 * Fetch data from the API using the specified bearer tokenx
 *
 * @param {string} url The relative API URL from which to fetch the data
 * @param {string} token The bearer token to use for authentication
 * @returns {Promise<object>} A promise that resolves to an object containing the data from the API.
 */
export async function fetchData(url, token) {
  try {
    const response = await axios.get(`${BASE_URL}/${url}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.data.data) {
      return {};
    }

    return response.data.data;

  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      // alert(`An error occurred: ${error.response.status}`);
      let msg;
      if (error.response.data?.message) {
        msg = error.response.data.message;
      } else {
        msg = error.response.statusText;
      }
      throw new Error(error.response.status + ": " + msg);
    } else if (error.request) {
      // Request was made but no response was received
      // alert('No response from server');
      throw new Error('No response from server');
    } else {
      // Something else went wrong
      // alert(`Error: ${error.message}`);
      throw new Error(error.message);
    }
  }
}

/**
 * Fetch API data that has a list of multiple objects
 *
 * @param {string} url The relative API URL from which to fetch the data
 * @param {string} token The bearer token to use for authentication
 * @param {boolean} paginated A flag indicating whether the URL returns a paginated response
 * @returns {Promise<object>} A promise that resolves to an object containing the data
 */
export async function fetchList(url, token, paginated=true) {
  const data = await fetchData(url, token);
  let list;
  if (paginated) {
    list = data.data;
  } else {
    list = data;
  }

  const next_url = data.links?.next_page_url;
  const prev_url = data.links?.previous_page_url;

  return {
    data: list,
    next_url,
    prev_url
  }
}