import Header from "../Header";
import Sidebar from "../Sidebar";
import React from "react";
import ContentWrapper from "./ContentWrapper";
import {Link} from "react-router-dom";
import ControlledComponent from "../AccessControl/ControlledComponent";
import Admin_Dashboard from "./Admin_Dashboard/Admin_Dashboard";
import Patient_Dashboard from "./Patient_Dashboard/Patient_Dashboard";
import Doctor_Dashboard from "./Doctor_Dashboard/Doctor_Dashboard";

export default function DashBoard() {

  return (
    <>
      <Header/>
      <Sidebar
        activeClassName="dashboard"
      />
      <div className="page-wrapper">
        <ContentWrapper>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">My Dashboard</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}

          <ControlledComponent allowedRoles={["admin"]}>
            <Admin_Dashboard />
          </ControlledComponent>
          <ControlledComponent allowedRoles={["patient"]}>
            <Patient_Dashboard />
          </ControlledComponent>
          <ControlledComponent allowedRoles={["caregiver"]} excludedRoles={["admin"]}>
            <Doctor_Dashboard />
          </ControlledComponent>
        </ContentWrapper>
      </div>
    </>
  );
}