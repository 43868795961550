/* eslint-disable no-unused-vars */
import {useLocation, useNavigate, useNavigationType, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from "react";

/**
 * Custom hook that provides navigation utilities including:
 * - Access to query parameters from the current URL.
 * - A `goBack` function that navigates the user to the previous page or
 * to a fallback route (e.g., dashboard) if the previous page is unavailable.
 *
 * @returns {Object} An object containing:
 *   - `queryParams`: An instance of `URLSearchParams` for the current query parameters.
 *   - `setQueryParams` - A function for setting the values of multiple query parameters by
 *   specifying them as key/value pairs as an argument to the function
 *   - `goBack`: A function that either navigates back to the previous page or
 *   to the root route if the navigation type is `POP`.
 *   - `reload`: A function for reloading the current page
 */
export default function useCustomNavigation() {
  const location = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [queryParams, _setQueryParams] = useSearchParams();
  const [currentURL, setCurrentURL] = useState('');
  // For use in pages with bootstrap tabs
  const [activeTab, setActiveTab] = useState(null);

  /**
   * Return the className attribute to use to toggle a tab or pane
   * @param {string} objID - The id of the tab
   * @param {string} defaultTab - The id of the default active tab
   * @param {boolean} isPane - true if you are setting the classname of a pane
   * @returns {string}
   */
  function activeTabClassName(objID, defaultTab, isPane=false) {
    let className = "";
    if (!activeTab) {
      if (objID === defaultTab) {
        className += "active";
        if (isPane) {
          className += " show";
        }
      }
    } else if (activeTab === objID) {
      className += "active";
      if (isPane) {
        className += " show";
      }
    }

    return className;
  }

  /**
   * Sets the name of the active tab in the query parameters
   * @param {string} tabName - the name of the active tab
   */
  function setActiveTabURL(tabName) {
    setQueryParams({tab: tabName});
  }

  useEffect(() => {
    setCurrentURL(location.pathname + location.search);
    setActiveTab(queryParams.get("tab"));
  }, [location])

  function reload() {
    navigate(0);
  }
  /**
   * Navigate back to the previous page or back to the dashboard
   */
  function goBack() {
    if (navigationType === 'POP') {
      navigate('/'); // Redirect to a fallback route
    } else {
      navigate(-1); // Go back to the previous page
    }
  }

  function setQueryParams(params) {
    Object.keys(params).forEach((key) => {
      queryParams.set(key, params[key]);
    });
    _setQueryParams(queryParams);
  }

  return ({queryParams, setQueryParams, goBack, reload, currentURL, activeTabClassName, setActiveTabURL});
}