import {fetchData} from "./fetchData"

/**
 * Fetch data for a specific role
 *
 * @param {string} roleName - name of role to look up
 * @param {string} token - bearer auth token
 * @returns the data for the specified role, or all roles if roleName is not specified
 */
export async function getRole(roleName=null, token) {
  const roles = await fetchData("role", token)
  if (!roleName) {
    return roles;
  }
  const match =  roles.find((obj) => obj.name.toLowerCase() === roleName.toLowerCase());
  if (!match) {
    return null;
  } else {
    return match;
  }
}
