/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/AuthProvider";
import {Link} from "react-router-dom";
import ControlledComponent from "../AccessControl/ControlledComponent";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import {Table} from "antd";
import {itemRender, onShowSizeChange} from "../Pagination";
import {fetchList} from "../../api/fetchData";
import PropTypes from "prop-types";
import ActionModal from "../Ui_Elements/ActionModal";
import {useNotifications} from "../../hooks/NotificationProvider";
import {deleteData} from "../../api/deleteData";

export default function LicensesAndCertificationsTable({caregiverID, type}) {
  const {token} = useAuth();
  const notifications = useNotifications();
  const [data, setData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [rowID, setRowID] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      // Capitalize the first letter
      title: `${type.charAt(0).toUpperCase() + type.slice(1)} name`,
      dataIndex: `${type}_name`,
      render: (text, record) => (<>{text || "N/A"}</>)
    },
    {
      title: `${type === 'license' ? 'License Number' : ""}`,
      dataIndex: `${type === 'license' ? 'license_number' : ""}`,
    },
    {
      title: "Issued By",
      dataIndex: "issued_by",
      render: (text, record) => (
        <>
          {
            record.issued_by ? text : "N/A"
          }
        </>
      )
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      render: (text, record) => (
        <>
          {
            record.issue_date ? text : "N/A"
          }
        </>
      )
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      render: (text, record) => (
        <>
          {
            record.expiry_date ? text : "N/A"
          }
        </>
      )
    },
    {
      title: "Verification Status",
      dataIndex: "license_verified_by",
      render: (text, record) => (
        <>
          {
            record.license_verified_by ? <span className="badge bg-success-light">Verified</span>
              : <span className="badge bg-danger-light">Pending</span>
          }
        </>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <ControlledComponent allowedRoles={["admin"]} allowedUsers={[caregiverID]}>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v"/>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item"
                      to={`/add-qualification?type=${type}&caregiverID=${caregiverID}&id=${record.id}`}>
                  <i className="far fa-edit me-2"/>
                  Edit
                </Link>
                <Link className="dropdown-item"
                      to="#"
                      onClick={() => {
                        setRowID(record.id);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#action-modal">
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </ControlledComponent>
      ),
    }
  ];

  async function deleteItem() {
    try {
      setLoading(true);
      if (!rowID) {
        notifications.addNotification("Row id is null", "error");
      } else if (type === "license") {
        await deleteData(`caregiver-licensing/${rowID}`, token);
        notifications.addNotification("Item successfully deleted", "success");
        setRefreshKey(refreshKey => refreshKey + 1);
      } else if (type === "certification") {
        await deleteData(`caregiver-certification/${rowID}`, token);
        notifications.addNotification("Item successfully deleted", "success");
        setRefreshKey(refreshKey => refreshKey + 1);
      } else {
        notifications.addNotification(`Invalid type: '${type}'`, "error");
      }
    } catch (err) {
      notifications.addNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        if (caregiverID && type) {
          if (type.toLowerCase() === "license") {
            const apiData = await fetchList(`caregiver-licensing?paginate=0&health_care_personnel_id=${caregiverID}`, token, false);
            setData(apiData);
          } else if (type.toLowerCase() === "certification") {
            const apiData = await fetchList(`caregiver-certification?paginate=0&health_care_personnel_id=${caregiverID}`, token, false);
            setData(apiData);
          } else {
            setError(`The document type '${type}' is not supported. Must be either 'license' or 'certification'`);
          }
        } else {
          setError("Either the staff id or document type has not been specified");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [caregiverID, refreshKey]);

  return (
    <>
      {
        error ? <Banner text={error} type="error" dismissible={false}/> :
          <div className="row">
            <div className="col-sm-12">
              <ControlledComponent allowedRoles={["admin"]} allowedUsers={[caregiverID]}>
                <div className="float-end">
                  <Link
                    to={`/add-qualification?type=${type}&caregiverID=${caregiverID}`}
                    className="btn btn-primary">
                    Add a {type}
                  </Link>
                </div>
              </ControlledComponent>
              <div className="card card-table">
                <div className="card-body p-4">
                  {loading ? <SpinAnimation/> : <div className="table-responsive">
                    <Table
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={data.data}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                  }
                </div>
              </div>
            </div>
            <ActionModal
              actionOnAccept={deleteItem}
              text="Are you sure you want to delete this item?"
            />
          </div>
      }
    </>
  )
}

LicensesAndCertificationsTable.propTypes = {
  caregiverID: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}
