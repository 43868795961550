/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
// import { DatePicker} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import Select from "react-select";
import {Link, Route} from 'react-router-dom';
import CertificationForm from "./CertificationForm";
import {DatePicker} from "antd";
import Select from "react-select";

const AddPersonForm = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const [department, setDepartment] = useState([
    { value: 2, label: "Orthopedics" },
    { value: 3, label: "Radiology" },
    { value: 4, label: "Dentist" },
  ]);
  const loadFile = (event) => {
    // Handle file loading logic here
  };

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-12">
                  <div className="form-heading">
                    <h4>Person Details</h4>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group local-forms">
                    <label>
                      Full name <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Phone Number <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Email <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder=""
                      autoComplete="username"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms cal-icon">
                    <label>
                      Date Of Birth (Optional)
                    </label>
                    {/* <input
                              className="form-control datetimepicker"
                              type="text"
                              placeholder=""
                            /> */}
                    <DatePicker
                      className="form-control datetimepicker"
                      onChange={onChange}
                      suffixIcon={null}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms cal-icon">
                    <label>
                      Date Hired
                    </label>
                    <DatePicker
                      className="form-control datetimepicker"
                      onChange={onChange}
                      suffixIcon={null}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group select-gender">
                    <label className="gen-label">
                      Gender<span className="login-danger">*</span>
                    </label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          name="gender"
                          className="form-check-input"
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          name="gender"
                          className="form-check-input"
                        />
                        Female
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Address <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="form-group local-forms">
                    <label>
                      Bio
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      cols={30}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-top-form">
                    <label className="local-top">
                      Profile Picture <span className="login-danger">*</span>
                    </label>
                    <div className="settings-btn upload-files-avator">
                      <input
                        type="file"
                        accept="image/*"
                        name="image"
                        id="file"
                        onChange={loadFile}
                        className="hide-input"
                      />
                      <label htmlFor="file" className="upload">
                        Choose File
                      </label>
                    </div>

                  </div>
                </div>
                <div className="col-12">
                  <div className="doctor-submit text-end">
                    <button
                      type="submit"
                      className="btn btn-primary submit-form me-2"
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary cancel-form"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPersonForm;
