import {useAuth} from "../../hooks/AuthProvider";
import ContentWrapper from "../Dashboard/ContentWrapper";
import React, {useEffect, useState} from "react";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import Banner from "../Ui_Elements/Banner";
import Header from "../Header";
import Sidebar from "../Sidebar";

export default function Logout() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {logOut} = useAuth();

  useEffect(() => {
    async function _logout() {
      try {
        setLoading(true);
        await logOut();
      } catch (err) {
        setError(err.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    _logout();
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
      <ContentWrapper className="container-fluid">
        <div className="row">
          {
            loading ?
              <>
                <h3 className="text-center mb-5">Logging you out, please wait</h3>
                <SpinAnimation />
              </> :
                <>
                {
                  error ? <Banner error={error} dismissible={false} type="error"/> :
                    null
                  }</>
          }
        </div>
      </ContentWrapper>
    </div>
    </>
  );
}