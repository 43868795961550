/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {Link} from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import useCustomNavigation from "../../../hooks/useCustomNavigation";
import ContentWrapper from "../../Dashboard/ContentWrapper";
import {useAuth} from "../../../hooks/AuthProvider";
import InvoiceTableWithFilter from "./InvoiceTableWithFilter";

const InvoiceList = () => {

  const {userRoles} = useAuth();
  const {queryParams} = useCustomNavigation();
  const [invoiceType, setInvoiceType] = useState("");

  useEffect(() => {
    setInvoiceType(queryParams.get('type'));
  }, [queryParams]);

  return (
    <>
      <Header/>
      <Sidebar
        id={userRoles.includes("admin") ? "menu-item13" : ""}
        id1={userRoles.includes("admin") ? "menu-items13" : ""}
        activeClassName={
          userRoles.includes("admin") ? `${invoiceType}-invoice-list`
            : "my-invoice-list"
        }
      />
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/invoice-list">Invoice </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{invoiceType} invoice list</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <InvoiceTableWithFilter
              invoiceType={invoiceType}
            />
          </ContentWrapper>
        </div>
      </>
    </>
  );
};


export default InvoiceList;
