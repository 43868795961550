import {useAuth} from "../../hooks/AuthProvider";
import PropTypes from "prop-types";

/**
 *
 * @param children - child nodes for which access control is to be enforced
 * @param allowedRoles - an array of roles allowed to access this component
 * @param excludedRoles - an array of roles that are not allowed to access this component
 * @param allowedUsers - an array of user IDs allowed to access this component
 * @returns {*|null}
 * @constructor
 */
export default function ControlledComponent({children, allowedRoles=[], excludedRoles=[], allowedUsers=[]}) {
  const {userRoles, user} = useAuth();
  let allowed = false;

  for (let i = 0; i < allowedRoles.length; i++) {
    if (userRoles.includes(allowedRoles[i])) {
      allowed = true;
      break;
    }
  }

  if (allowedUsers.includes(user.id)) {
    allowed = true;
  }

  for (let i = 0; i < excludedRoles.length; i++) {
    if (userRoles.includes(excludedRoles[i])) {
      allowed = false;
      break;
    }
  }

  return (
    allowed ? children : null
  );
}

ControlledComponent.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array,
  excludedRoles: PropTypes.array,
  allowedUsers: PropTypes.array
}