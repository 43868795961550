/* eslint-disable no-unused-vars */
import React from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {calendar, empty_wallet, morning_img_01, profile_add} from "../../imagepath";
import CountUp from "react-countup";
import {useAuth} from "../../../hooks/AuthProvider";
import AppointmentsCalendar from "../../appointments/AppointmentsCalendar";

const Admin_Dashboard = () => {
  const {user} = useAuth();

  return (
    <>
      <div className="good-morning-blk">
        <div className="row">
          <div className="col-md-6">
            <div className="morning-user">
              {
                user ? <h2>Hello, <span>{user.name}</span></h2> :
                  <h2>Hello, <span>You are currently logged out</span></h2>
              }
              <p>Have a nice day at work</p>
            </div>
          </div>
          <div className="col-md-6 position-blk">
            <div className="morning-img">
              <img src={morning_img_01}
                   alt="#"/>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <div className="dash-boxs comman-flex-center">
              <img src={calendar} alt="#"/>
            </div>
            <div className="dash-content dash-count flex-grow-1">
              <h4>New Appointments</h4>
              <h2>
                {" "}
                <CountUp delay={0.4} end={250} duration={0.6}/>
              </h2>
              <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                        </i>
                        40%
                      </span>{" "}
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <div className="dash-boxs comman-flex-center">
              <img src={profile_add} alt="#"/>
            </div>
            <div className="dash-content dash-count">
              <h4>New Patients</h4>
              <h2>
                <CountUp delay={0.4} end={140} duration={0.6}/>
              </h2>
              <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                          </i>
                        20%
                      </span>{" "}
                vs last month
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <div className="dash-boxs comman-flex-center">
              <img src={profile_add} alt="#"/>
            </div>
            <div className="dash-content dash-count">
              <h4>New Healthcare Staff</h4>
              <h2>
                <CountUp delay={0.4} end={33} duration={0.6}/>
              </h2>
              <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                          </i>
                        10%
                      </span>{" "}
                vs last month
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <div className="dash-boxs comman-flex-center">
              <img src={empty_wallet} alt="#"/>
            </div>
            <div className="dash-content dash-count">
              <h4>Earnings</h4>
              <h2>
                KES <CountUp delay={0.4} end={200250} duration={0.6}/>
              </h2>
              <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                          </i>
                        30%
                      </span>{" "}
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <AppointmentsCalendar role={"admin"} userID={user.id}/>
      </div>
    </>
  );
};

export default Admin_Dashboard;
