import axios from 'axios';

import {BASE_URL} from "../settings";


/**
 * Delete data from the API using the specified bearer token
 *
 * @param {string} url The relative API URL from which to delete the data
 * @param {string} token The bearer token to use for authentication
 * @returns {Promise<object>} A promise that resolves to an object containing the response from the API.
 */
export async function deleteData(url, token) {
  try {
    const response = await axios.delete(`${BASE_URL}/${url}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data;

  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      let msg;
      if (error.response.data?.message) {
        msg = error.response.data.message;
      } else {
        msg = error.response.statusText;
      }
      throw new Error(`${error.response.status}: ${msg}`);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error('No response from server');
    } else {
      // Something else went wrong
      throw new Error(error.message);
    }
  }
}
