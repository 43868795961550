import axios from 'axios';
import {BASE_URL} from "../settings";

/**
 * Fetch data from the API using the specified bearer tokenx
 *
 * @param {string} url The relative API URL from which to fetch the data
 * @param {string} token The bearer token to use for authentication
 * @param {object} jsonData Data to be sent to the API
 * @returns {Promise<object>} A promise that resolves to an object containing the data from the API.
 */
export async function postData(url, token, jsonData) {
  try {
    const response = await axios.post(`${BASE_URL}/${url}`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data.data;

  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      let msg;
      if (error.response.data?.message) {
        msg = error.response.data.message;
      } else {
        msg = error.response.statusText;
      }
      throw new Error(error.response.status + ": " + msg);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error('No response from server');
    } else {
      // Something else went wrong
      throw new Error(error.message);
    }
  }
}