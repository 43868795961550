/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import {DatePicker} from "antd";
import Select from "react-select";
import React, {useState} from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import useCustomNavigation from "../../hooks/useCustomNavigation";

export default function FilterBar({invoiceStatusOptions, appointmentStatusOptions}) {
  const {setQueryParams} = useCustomNavigation();

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedInvoiceStatusOption, setSelectedInvoiceStatusOption] = useState(null);
  const _invoiceStatusOptions = invoiceStatusOptions?.map(
    (value, index) => (
      {value: index, label: value}
    )
  );
  const [selectedAppointmentStatusOption, setSelectedAppointmentStatusOption] = useState(null);
  const _appointmentStatusOptions = appointmentStatusOptions?.map(
    (value, index) => (
      {value: index, label: value}
    )
  );

  function handleDateFromChange(date, dateString) {
    setDateFrom(dateString);
  }

  function handleDateToChange(date, dateString) {
    setDateTo(dateString);
  }

  function onSearchButtonClick(event) {
    event.preventDefault();
    const invoiceStatus = selectedInvoiceStatusOption?.label || "";
    const appointmentStatus = selectedAppointmentStatusOption?.label || "";
    setQueryParams({dateFrom, dateTo, invoiceStatus, appointmentStatus});
  }

  // useEffect(() => {
  //   const status = selectedStatusOption?.label || "";
  //   setQueryParams({dateFrom, dateTo, status});
  // }, [dateFrom, dateTo, selectedStatusOption]);

  return (
    <div className="card">
      <div className="staff-search-table">
        <form>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group local-forms cal-icon">
                <label>From
                </label>
                <DatePicker
                  className="form-control datetimepicker"
                  onChange={handleDateFromChange}
                  suffixIcon={null}
                  value={dateFrom ? dayjs(dateFrom, "YYYY-MM-DD") : ""}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group local-forms cal-icon">
                <label>To
                </label>
                <DatePicker
                  className="form-control datetimepicker"
                  onChange={handleDateToChange}
                  suffixIcon={null}
                  value={dateTo ? dayjs(dateTo, "YYYY-MM-DD") : ""}
                />
              </div>
            </div>
            {
              invoiceStatusOptions ?
                <div className="col-12 col-md-6 col-xl-3 ">
                  <div className="form-group local-forms">
                    <label>Invoice Status
                    </label>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                      onChange={setSelectedInvoiceStatusOption}
                      options={_invoiceStatusOptions}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                          },
                          borderRadius: '10px',
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: '250ms',
                          width: '35px',
                          height: '35px',
                        }),
                      }}
                    />
                  </div>
                </div> : null
            }
            {
              appointmentStatusOptions ?
                <div className="col-12 col-md-6 col-xl-3 ">
                  <div className="form-group local-forms">
                    <label>Appointment Status
                    </label>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                      onChange={setSelectedAppointmentStatusOption}
                      options={_appointmentStatusOptions}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                          },
                          borderRadius: '10px',
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: '250ms',
                          width: '35px',
                          height: '35px',
                        }),
                      }}
                    />
                  </div>
                </div> : null
            }
            <div className="col-12 col-md-6 col-xl-3 ms-auto">
              <div className="doctor-submit">
                <button
                  className="btn btn-primary submit-list-form me-2"
                  onClick={onSearchButtonClick}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  );
}

FilterBar.propTypes = {
  invoiceStatusOptions: PropTypes.arrayOf(PropTypes.string),
  appointmentStatusOptions: PropTypes.arrayOf(PropTypes.string),
};