import { useEffect } from 'react';
import { useNotifications} from "./NotificationProvider"; // Update path as needed

/**
 * Send an error notification.
 *
 * @param {string} error - the error message to be displayed. Should be a useState string variable
 */
export default function useErrorNotification(error) {
  const notifications = useNotifications();

  useEffect(() => {
    if (error) {
      notifications.addNotification(error, "error");
    }
  }, [error]);
}
