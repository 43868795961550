/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {DatePicker} from 'antd';
import Delete_Invoices from './Delete_Invoices';
import Save_Invoice from './Save_Invoice';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import dayjs from "dayjs";
import useErrorNotification from "../../../hooks/useErrorNotification";
import {useNotifications} from "../../../hooks/NotificationProvider";
import PropTypes from "prop-types";
import {fetchData} from "../../../api/fetchData";
import {useAuth} from "../../../hooks/AuthProvider";
import {postData} from "../../../api/postData";
import {deleteData} from "../../../api/deleteData";
import ContentWrapper from "../../Dashboard/ContentWrapper";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import Banner from "../../Ui_Elements/Banner";
import {putData} from "../../../api/putData";
import useCustomNavigation from "../../../hooks/useCustomNavigation";

const tax_rate = 0.16;

/**
 *
 * @param appointmentID
 * @param invoiceID
 * @param type - either "patient" or "caregiver"
 * @param patientID
 * @param caregiverID
 * @returns {React.JSX.Element}
 * @constructor
 */
const AddInvoiceForm = ({appointmentID, invoiceID, invoiceType, patientID, caregiverID}) => {

  const {token} = useAuth();
  const {goBack} = useCustomNavigation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fatalError, setFatalError] = useState(false);
  const notifications = useNotifications();
  useErrorNotification(error);

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(dayjs());
  const [invoiceDueDate, setInvoiceDueDate] = useState(dayjs());
  const [notes, setNotes] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientMobile, setRecipientMobile] = useState("");
  const [recipientLocation, setRecipientLocation] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [invoiceTaxable, setInvoiceTaxable] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);

  // Props for keeping track of the fields for the invoice item being created.
  // When a row is populated and saved, these values are bundled into an object
  //  and appended to the `rows` prop. The values are then reset, awaiting input
  //  for the next row.
  const [rowName, setRowName] = useState("");
  const [rowDescription, setRowDescription] = useState("");
  const [rowDiscount, setRowDiscount] = useState(0);
  const [rowAmount, setRowAmount] = useState(0);
  const [_invoiceID, _setInvoiceID] = useState(invoiceID);

  const [rows, setRows] = useState([]);

  const onInvoiceDateChange = (date, dateString) => {
    setInvoiceDate(date);
  };

  const onInvoiceDueDateChange = (date, dateString) => {
    setInvoiceDueDate(date);
  };


  const addRow = async () => {
    setTableLoading(true);
    if (!rowIsValid()) {
      setTableLoading(false);
      return;
    }

    const newRow = {
      item_name: rowName,
      item_description: rowDescription,
      amount: rowAmount,
      discount_amount: rowDiscount,
      tax_amount: (rowAmount - rowDiscount) * tax_rate,
      total_amount: (rowAmount - rowDiscount) * (1 + tax_rate),
      tax_rate,
      currency: "KES"
    };

    if (invoiceType === "patient") {
      newRow.patient_invoice_id = _invoiceID;
    } else if (invoiceType === "caregiver") {
      newRow.health_care_personnel_invoice_id = _invoiceID;
    } else {
      setError("Invoice type is invalid");
      setFatalError(true);
      setTableLoading(false);
      return;
    }

    try {
      // Send the invoice item to the db
      const result = await postData("invoice-item", token, newRow);
      newRow.id = result.id;
      setRows([...rows, newRow]);
      // Reset the row variables
      setRowName("");
      setRowDescription("");
      setRowDiscount(0);
      setRowAmount(0);
      console.log(`Invoice item #${newRow.id} added successfully`);
    } catch (err) {
      setError(err.message);
    } finally {
      setTableLoading(false);
    }

    setTableLoading(false);
  };

  async function removeRow(id) {
    setTableLoading(true);
    try {
      await deleteData(`invoice-item/${id}`, token);
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      notifications.addNotification("Item successfully deleted", "success")
    } catch (err) {
      setError(err.message);
    } finally {
      setTableLoading(false);
    }
  }

  // Check if form data is valid
  function formHasError() {
    let hasError = false;
    if (!invoiceDueDate || !invoiceDate) {
      setError("Both the invoice date and the due date must be provided");
      hasError = true;
    } else if (!(invoiceDueDate.isAfter(invoiceDate) || invoiceDueDate.isSame(invoiceDate))) {
      setError("The due date should not be a date earlier than the invoice date");
      hasError = true;
    } else if (rows.length === 0) {
      setError("At least one invoice item should be added");
      hasError = true;
    }
    return hasError;
  }

  async function onFormSubmit(event) {
    setLoading(true);
    event.preventDefault();

    const formData = {
      invoice_date: invoiceDate.toISOString(),
      due_date: invoiceDueDate.toISOString(),
      appointment_schedule_id: appointmentID,
      notes: notes,
      addressed_to: recipientName,
      invoice_status: invoiceStatus || "Pending",
    };
    if (invoiceType === "patient") {
      formData.patient_id = patientID;
    } else if (invoiceType === "caregiver") {
      formData.health_care_personnel_id = caregiverID;
    } else {
      setError("Invalid Type is invalid.");
      setFatalError(true);
    }

    if (event.target.checkValidity() && !formHasError()) {
      try {
        await putData(`${invoiceType}-invoice/${_invoiceID}`, token, formData);
        notifications.addNotification("Invoice successfully updated", "success");
        setLoading(false);
        console.log(`Updated invoice # ${_invoiceID}`);
        goBack();
      } catch (err) {
        setError(err.message);
      }
    }
    setLoading(false);
  }

  // Validate row variables before adding the row to the table
  function rowIsValid() {
    let isValid = true;
    if (!rowName) {
      setError("Service name is required");
      isValid = false;
    } else if (isNaN(rowAmount)) {
      setError("'Amount' must be a valid number > 0");
      isValid = false;
    } else if (isNaN(rowDiscount)) {
      setError("'Discount' must be a valid number ");
      isValid = false;
    } else if (rowDiscount > rowAmount) {
      setError("Discount cannot be greater than the amount");
      isValid = false;
    }
    return isValid;
  }

  function renderTableRows() {
    return (
      rows.map((row) => (
        <tr key={row.id}>
          <td>
            {
              <input
                className="form-control"
                value={row.item_name || ""}
                disabled={true}
              />
            }
          </td>
          <td>
            {
              <textarea
                className="form-control"
                value={row.item_description || ""}
                disabled={true}
              />
            }
          </td>
          <td>
            {
              <input
                type="text"
                className="form-control"
                value={row.amount || ""}
                disabled={true}
              />
            }
          </td>
          <td>
            {
              <input
                type="text"
                className="form-control"
                value={row.discount_amount?.toLocaleString() || ""}
                disabled={true}
              />
            }
          </td>
          <td>
            {
              <input
                type="text"
                className="form-control"
                value={row.total_amount?.toLocaleString() || ""}
                disabled={true}
              />
            }
          </td>
          <td className="add-remove text-end">
            <div
              className="remove-btn me-2"
              onClick={(e) => removeRow(row.id)}
            >
              <i className="fe fe-trash-2">
                <FeatherIcon icon="trash-2"/>
              </i>
            </div>
          </td>
        </tr>
      ))
    );
  }

  function renderForm() {
    return (
      loading ? <ContentWrapper className="container-fluid">
          <SpinAnimation/>
        </ContentWrapper> :
        <>
          <ContentWrapper className="container-fluid">
            {/* Page Header */}
            <div className="page-header invoices-page-header">
              <div className="row align-items-center">
                <div className="col">
                  {/*<ul className="breadcrumb invoices-breadcrumb">*/}
                  {/*  <li className="breadcrumb-item invoices-breadcrumb-item">*/}
                  {/*    <Link*/}
                  {/*      to="#"*/}
                  {/*      onClick={goBack}*/}
                  {/*    >*/}
                  {/*      <i className="fa fa-chevron-left"/> Back to previous page*/}
                  {/*    </Link>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </div>
                <div className="col-auto">
                  <div className="invoices-create-btn">
                    <Link
                      to={"#"}
                      data-bs-toggle="modal"
                      data-bs-target="#delete_invoices_details"
                      className="btn delete-invoice-btn"
                    >
                      Delete Invoice
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <div className="card invoices-add-card">
                  <div className="card-body">
                    <form onSubmit={onFormSubmit} className="invoices-form">
                      <div className="invoices-main-form">
                        <div className="row">
                          <div className="col-xl-5 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <h4 className="invoice-details-title">Recipient Details</h4>
                              <label>Name</label>
                              <input
                                type="text"
                                required={true}
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                                className="form-control"
                                placeholder="Enter Invoice Recipient Name"
                              />
                              <br/>
                              <label>Mobile</label>
                              <input
                                type="text"
                                required={true}
                                value={recipientMobile}
                                onChange={(e) => setRecipientMobile(e.target.value)}
                                className="form-control"
                                placeholder="Enter Invoice Recipient Phone Number"
                              />
                              <br/>
                              <label>Address</label>
                              <input
                                type="text"
                                required={true}
                                value={recipientAddress}
                                onChange={(e) => setRecipientAddress(e.target.value)}
                                className="form-control"
                                placeholder="Enter Invoice Recipient Address"
                              />
                              <br/>
                              <label>Location</label>
                              <input
                                type="text"
                                value={recipientLocation}
                                onChange={(e) => setRecipientLocation(e.target.value)}
                                required={true}
                                className="form-control"
                                placeholder="Enter Invoice Recipient Location"
                              />
                            </div>
                          </div>
                          <div className="col-xl-5 col-md-6 col-sm-12 col-12">
                            <h4 className="invoice-details-title">Invoice details</h4>
                            <div className="invoice-details-box">
                              <div className="invoice-inner-head">
                                <span>Invoice No. {invoiceNumber || invoiceID}</span>
                                {
                                  invoiceStatus ? <span>[{invoiceStatus}]</span> : ""
                                }
                              </div>
                              <div className="invoice-inner-head">
                                <span>Appointment No. {appointmentID}</span>
                              </div>
                              <div className="invoice-inner-footer">
                                <div className="row align-items-center">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="invoice-inner-date">
                                      <span>
                                          Issue Date
                                        <DatePicker
                                          className="form-control datetimepicker"
                                          onChange={onInvoiceDateChange}
                                          value={invoiceDate}
                                          suffixIcon={null}
                                          placeholder='24/11/2022'
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="invoice-inner-date invoice-inner-datepic">
                                      <span>
                                          Due Date
                                           <DatePicker
                                             className="form-control datetimepicker"
                                             value={invoiceDueDate}
                                             onChange={onInvoiceDueDateChange}
                                             suffixIcon={null}
                                           />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-add-table">
                        <h4>Invoice Items</h4>
                        <div className="table-responsive">
                          <table className="table table-center add-table-items">
                            <thead>
                            <tr>
                              <th>Service Name</th>
                              <th>Description</th>
                              <th>Amount (KES)</th>
                              <th>Discount (KES)</th>
                              <th>Total (VAT Inc.)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="add-row">
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={rowName}
                                  onChange={(e) => {
                                    setRowName(e.target.value)
                                  }}
                                />
                              </td>
                              <td>
                                <textarea
                                  className="form-control"
                                  value={rowDescription || ""}
                                  onChange={(e) => {
                                    setRowDescription(e.target.value)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={rowAmount}
                                  onChange={(e) => {
                                    setRowAmount(Number(e.target.value.trim()))
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={rowDiscount}
                                  onChange={(e) => {
                                    setRowDiscount(Number(e.target.value.trim()))
                                  }}
                                />
                              </td>
                              <td>
                                <span></span>
                              </td>
                              <td className="add-remove text-end"
                                  style={{display: "flex", gap: "2px", alignItems: "center"}}>
                                <div

                                  className="add-btn me-2"
                                  onClick={addRow}
                                >
                                  <i className="fas fa-plus-circle"/>
                                </div>
                              </td>
                            </tr>
                            {
                              tableLoading ? <tr>
                                <td><SpinAnimation/></td>
                              </tr> : renderTableRows()
                            }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-7 col-md-6">
                          <div className="invoice-fields">
                            <h4 className="field-title">Notes</h4>
                          </div>
                          <div className="invoice-faq">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div className="faq-tab">
                                <div className="panel panel-default">
                                  <div className="panel-heading" role="tab" id="headingThree">
                                    <p className="panel-title">
                                      <Link className="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion"
                                            to="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <i className="fas fa-plus-circle me-1"/> Add a note
                                      </Link>
                                    </p>
                                  </div>
                                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel"
                                       aria-labelledby="headingThree" data-bs-parent="#accordion">
                                    <div className="panel-body">
                                      <textarea
                                        className="form-control"
                                        value={notes || ""}
                                        onChange={(e) => setNotes(e.target.value)}
                                        placeholder="Type your notes here"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <div className="invoice-total-card">
                            <h4 className="invoice-total-title">Summary</h4>
                            <div className="invoice-total-box">
                              <div className="invoice-total-inner">
                                <p>
                                  Taxable Amount <span>KES {invoiceTaxable?.toLocaleString()}</span>
                                </p>
                              </div>
                              <div className="invoice-total-inner">
                                <p>
                                  Tax <span>KES {taxTotal?.toLocaleString()}</span>
                                </p>
                              </div>
                              <div className="invoice-total-footer">
                                <h4>
                                  Total Amount <span>{invoiceTotal?.toLocaleString()}</span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="upload-sign">
                            <div className="form-group float-end mb-0">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                onClick={onFormSubmit}
                              >
                                Save Invoice
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>
          {/* modal */}
          <Save_Invoice/>
          <Delete_Invoices invoiceType={invoiceType} invoiceID={_invoiceID}/>
          {/* modal */}
        </>
    );
  }

  // If invoiceID has been provided, pre-populate the fields with the appropriate values,
  // otherwise create the invoice and pre-populate with default values
  useEffect(() => {
    async function populateFields() {
      setLoading(true);
      if (!invoiceID) {
        // Create the invoice
        try {
          const patientOrCaregiverDetails = await fetchData(`user/${patientID || caregiverID}`, token);
          const currentDateTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
          const data = {
            appointment_schedule_id: appointmentID,
            invoice_date: currentDateTime,
            due_date: currentDateTime,
            addressed_to: patientOrCaregiverDetails.name
          }

          if (invoiceType === "patient") {
            data.patient_id = patientID;
          } else if (invoiceType === "caregiver") {
            data.health_care_personnel_id = caregiverID;
          } else {
            setError("Invoice type is invalid");
            setFatalError(true);
            return;
          }

          const result = await postData(`${invoiceType}-invoice`, token, data);
          setRecipientName(patientOrCaregiverDetails.name);
          setRecipientMobile(patientOrCaregiverDetails.phone_number || "");
          setRecipientLocation(patientOrCaregiverDetails.location || "");
          setRecipientAddress(patientOrCaregiverDetails.location || "");
          setInvoiceNumber(result.invoice_number || result.id);
          _setInvoiceID(result.id);

          notifications.addNotification("Invoice creation successful, proceed to edit the details");

        } catch (err) {
          setError(err.message);
          // Abort the invoice creation process
          setFatalError(true);
        }
      } else {
        try {
          // Fetch invoice details and populate the forms
          const data = await fetchData(`${invoiceType}-invoice/${invoiceID}`, token);
          setRecipientName(data.addressed_to);
          setInvoiceStatus(data.invoice_status || "");
          setInvoiceDate(dayjs(data.invoice_date));
          setInvoiceDueDate(dayjs(data.due_date));
          data.invoice_items.map((item) => {
            item['amount'] = Number(item['amount']) || 0;
            item['tax_amount'] = Number(item['tax_amount']) || 0;
            item['discount_amount'] = Number(item['discount_amount']) || 0;
            item['total_amount'] = Number(item['total_amount']) || 0;
          });
          setRows(data.invoice_items);
          setInvoiceNumber(data.invoice_number);
          setNotes(data.notes || "");
        } catch (err) {
          setError(err.message);
          setFatalError(true);
        }
      }
      setLoading(false);
    }

    populateFields().then(() => {
      console.log("Invoice successfully loaded");
    });
  }, []);

  // Compute summary figures
  useEffect(() => {
    const _taxTotal = rows.reduce((total, obj) => total + obj.tax_amount, 0);
    const _taxableTotal = rows.reduce((total, obj) => total + obj.amount, 0);
    const _discountTotal = rows.reduce((total, obj) => total + obj.discount_amount, 0);
    const _total = rows.reduce((total, obj) => total + obj.total_amount, 0);
    setTaxTotal(_taxTotal);
    setInvoiceTotal(_total);
    setInvoiceTaxable(_taxableTotal - _discountTotal);

  }, [rows]);

  return (
    <>
      {
        fatalError ? <Banner type="error" dismissible={false} text={error}/> :
          renderForm()
      }
    </>
  )
}

AddInvoiceForm.propTypes = {
  appointmentID: PropTypes.string,
  patientID: PropTypes.string,
  caregiverID: PropTypes.string,
  invoiceID: PropTypes.string,
  invoiceType: PropTypes.string,
}

export default AddInvoiceForm;
