/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {login02, loginlogo, logo} from '../../imagepath';
import useCustomNavigation from "../../../hooks/useCustomNavigation";
import {Eye, EyeOff} from "feather-icons-react/build/IconComponents";
import {useNotifications} from "../../../hooks/NotificationProvider";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import {postData} from "../../../api/postData";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../hooks/AuthProvider";
import ContentWrapper from "../../Dashboard/ContentWrapper";

const ForgotPassword = () => {
  const {loginAction} = useAuth();
  const navigate = useNavigate();
  const {queryParams} = useCustomNavigation();
  const token = queryParams.get("token");
  const notifications = useNotifications();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(queryParams.get("email") || "");
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [password, setPassword] = useState('');
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [passwordVisible1, setPasswordVisible1] = useState(true);
  const [password1, setPassword1] = useState('');
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  async function onSubmit(event) {
    event.preventDefault();
    // Verify that passwords match
    if (token && (password !== password1)) {
      notifications.addNotification("Error: Passwords do not match", "error");
      return;
    }

    try {
      setLoading(true);
      if (event.target.checkValidity()) {
        let data;
        if (token) {
          data = {
            email,
            password,
            token,
            password_confirmation: password1,
          };
          await postData("reset-password", "", data);
          notifications.addNotification("Password reset successful", "success");
          loginAction({email, password});
        } else {
          data = {email};
          await postData("forgot-password", "", data);
          const msg = "Password request successful. Check email for reset link";
          notifications.addNotification(msg, "success");
          navigate("/login");
        }
      }
    } catch (e) {
      notifications.addNotification(e.message, "error");
    } finally {
      setLoading(false)
    }
  }

  function renderPasswordFields() {
    return (
      <>
        <div className="form-group">
          <label>
            Password <span className="login-danger">*</span>
          </label>
          <input
            type={passwordVisible ? 'password' : ''}
            className="form-control pass-input"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="toggle-password"
            onClick={togglePasswordVisibility}
          >
                          {passwordVisible ? <EyeOff className="react-feather-custom"/> :
                            <Eye className="react-feather-custom"/>}
                        </span>
        </div>
        <div className="form-group">
          <label>
            Confirm Password{" "}
            <span className="login-danger">*</span>
          </label>
          <input
            type={passwordVisible1 ? 'password' : ''}
            className="form-control pass-input"
            value={password1}
            required
            onChange={(e) => setPassword1(e.target.value)}
          />
          <span
            className="toggle-password"
            onClick={togglePasswordVisibility1}
          >
                          {passwordVisible1 ? <EyeOff className="react-feather-custom"/> :
                            <Eye className="react-feather-custom"/>}
                        </span>
          {/* <input
                            className="form-control pass-input-confirm"
                            type="password"
                          /> */}
          <span className="profile-views feather-eye-off confirm-password"/>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="main-wrapper login-body">
        <ContentWrapper className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <  Link to="/admin-dashboard">
                          <img src={logo} alt="#" width={120} height={30} />
                        </Link>
                      </div>
                      <h2>Reset Password</h2>
                      {/* Form */}
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        {
                          token ? renderPasswordFields() : null
                        }

                        <div className="form-group login-btn">
                          {
                            loading ? <SpinAnimation/> :
                              <button className="btn btn-primary btn-block" type="submit">
                                Reset Password
                              </button>
                          }
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account?
                          <Link to="/login"> Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </ContentWrapper>
      </div>


    </div>
  )
}

export default ForgotPassword;
