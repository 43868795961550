/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from "react";
import {DatePicker} from "antd";
import Select from "react-select";
import {TextField} from "@mui/material";
import {useNavigate, useNavigationType} from 'react-router-dom';
import {fetchData, fetchList} from "../../api/fetchData";
import {postData} from "../../api/postData";
import {putData} from "../../api/putData";
import {useAuth} from "../../hooks/AuthProvider";
import dayjs from "dayjs";
import useErrorNotification from "../../hooks/useErrorNotification";
import {useNotifications} from "../../hooks/NotificationProvider";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import PropTypes from "prop-types";
import Banner from "../Ui_Elements/Banner";

const AddAppointmentForm = ({appointmentID, caregiverID, patientID, appointmentDate}) => {
  const {token, user, userRoles} = useAuth();
  const [formDisabled, setFormDisabled] = useState(false);
  const notifications = useNotifications();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  // Mappings of id to user details. The key is the id and the value
  // is an object containing the other attributes as returned by the /user API endpoint
  const [patients, setPatients] = useState(new Map());
  const [doctors, setDoctors] = useState(new Map());

  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [date, setDate] = useState(appointmentDate || "");
  const [patientMobile, setPatientMobile] = useState("");
  const [doctorMobile, setDoctorMobile] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientAddress, setPatientAddress] = useState("");
  const [patientBio, setPatientBio] = useState("");
  const [patientIDNumber, setPatientIDNumber] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [doctorBio, setDoctorBio] = useState("");
  const [appointmentDescription, setAppointmentDescription] = useState("");
  const [doctorNames, setDoctorNames] = useState([]);
  const [patientNames, setPatientNames] = useState([]);
  const onDateChange = (date, dateString) => {
    setIsClicked(true);
    setDate(dateString);
  };

  useErrorNotification(error);

  const [serviceCategories, setServiceCategories] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");


  async function handleOnSubmit(event) {
    setLoading(true);
    event.preventDefault();
    if (!date) {
      setError("Appointment Date must be provided");
      setLoading(false);
      return;
    }
    if (!selectedService) {
      setError("Service must be provided");
      setLoading(false);
      return;
    }
    const jsonData = {
      "health_care_personnel_id": selectedDoctor.value,
      "patient_id": selectedPatient.value,
      "appointment_start_date": dayjs(date + " " + startTime).toISOString(),
      "appointment_end_date": dayjs(date + " " + endTime).toISOString(),
      "appointment_description": appointmentDescription || "N/A",
      "service_id": selectedService.value,
    }

    try {
      if (appointmentID) {
        const resp = await putData(`appointment-schedule/${appointmentID}`, token, jsonData);
        notifications.addNotification("Appointment successfully updated", "success");
        navigate(0);  // Refresh the page
      } else {
        const resp = await postData('appointment-schedule', token, jsonData);
        notifications.addNotification("Appointment successfully created", "success");
        // navigate(`/appointment-details?id=${resp.id}&patientID=${resp.patient_id}&caregiverID=${resp.health_care_personnel_id}`);
        goBack();
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  function createNamesList(data) {
    return data.map(user => {
      return {
        value: user.id,
        label: user.name,
      }
    });
  }

  function createMap(data) {
    return data.reduce((map, obj) => {
      const {id, ...otherFields} = obj;  // Extract 'id' and other fields
      map.set(id, otherFields);  // Set 'id' as key and other fields as value
      return map;
    }, new Map());
  }

  async function loadPatients() {
    try {
      if (userRoles.includes("patient")) {
        const names = createNamesList([user]);
        setPatientNames(names);
        const resultMap = createMap([user]);
        setPatients(resultMap);
        setSelectedPatient({
          value: user.id,
          label: user.name
        });
        return;
      }
      if (userRoles.includes("caregiver") && appointmentID) {
        // Do not load patient data from the api. The data will be loaded
        // from the appointment details since the user does not have permissions
        // to use the /user/* api
        return;
      }
      // Only admin can view list of all patients when creating an appointment
      if (!userRoles.includes("admin") && !appointmentID) {
        setFatalError(true);
        setError("Only patients and admins can create appointments");
        return;
      }
      const apiData = await fetchList("user?role=patient&paginate=0", token, false);
      // Sort by name
      apiData.data.sort((a, b) => {
        // Use localeCompare for string comparison
        return a.name.localeCompare(b.name);
      });
      const names = createNamesList(apiData.data);
      setPatientNames(names);

      // Create hashmap of id: user-details
      const resultMap = createMap(apiData.data);
      setPatients(resultMap);
    } catch (err) {
      setError(err.message);
    }
  }

  function getDateAndTime(dateStr) {
    const dateTime = dayjs(dateStr);
    const d = dateTime.format("YYYY-MM-DD");
    const t = dateTime.format("HH:mm:ss");
    return {date: d, time: t};
  }

  /**
   * Navigate back to the previous page or back to the dashboard
   */
  function goBack() {
    if (navigationType === 'POP') {
      navigate('/'); // Redirect to a fallback route
    } else {
      navigate(-1); // Go back to the previous page
    }
  }

  async function loadDoctors() {
    try {
      if (userRoles.includes("caregiver")) {
        const names = createNamesList([user]);
        setDoctorNames(names);
        const resultMap = createMap([user]);
        setDoctors(resultMap);
        setSelectedDoctor({
          value: user.id,
          label: user.name
        });
        return;
      }
      const apiData = await fetchList("user?role=caregiver&paginate=0", token, false);
      // Sort by name
      apiData.data.sort((a, b) => {
        // Use localeCompare for string comparison
        return a.name.localeCompare(b.name);
      });
      const names = createNamesList(apiData.data);
      setDoctorNames(names);

      // Create hashmap of id: user-details
      const resultMap = createMap(apiData.data);
      setDoctors(resultMap);
    } catch (err) {
      setError(err.message);
    }
  }

  async function loadAppointmentDetails() {
    if (!appointmentID) {
      return;
    }
    try {
      const details = await fetchData(`appointment-schedule/${appointmentID}`, token);
      const date_ = getDateAndTime(details.appointment_start_date).date;
      const startTime_ = getDateAndTime(details.appointment_start_date).time;
      const endTime_ = getDateAndTime(details.appointment_end_date).time;
      setDate(date_);
      setStartTime(startTime_);
      setEndTime(endTime_);
      setAppointmentDescription(details.appointment_description);
      setSelectedService({label: details.service_name, value: details.service_id});

      setSelectedPatient({
        value: details.patient_id,
        label: details.patient_name
      });

      if (userRoles.includes("caregiver")) {
        setPatientGender(details.patient_gender || "");
        setPatientMobile(details.patient_phone_number || "");
        setPatientIDNumber(details.patient_id_number || "");
        setPatientAddress(details.patient_location || "");
        setPatientBio(details.patient_bio || "");
        setPatientEmail(details.patient_email || "");
      }

      setSelectedDoctor({
        value: details.health_care_personnel_id,
        label: details.health_care_personnel_name
      });

    } catch (err) {
      setError(err.message);
    }
  }

  // Render services
  useEffect(() => {
    async function loadCategories() {
      try {
        const apiData = await fetchList("service-category", "", false);
        const cats = apiData.data.map(cat => {
          return {label: cat.name, value: cat.id};
        });
        setServiceCategories(cats);
      } catch (e) {
        console.log(`Unable to fetch categories: ${e.message}`);
        notifications.addNotification("Unable to fetch service categories", "error");
      }
    }

    async function loadServices() {
      try {
        const apiData = await fetchList("service", "", false);
        const groupedServices = apiData.data.reduce((acc, service) => {
          const category = service.service_category;
          // If the category key doesn't exist, initialize it as an empty array
          if (!acc[category]) {
            acc[category] = [];
          }
          // Add the current service to the category array
          acc[category].push({label: `${service.name} (KES. ${Number(service.price).toLocaleString()})`, value: service.id});
          return acc;
        }, {});
        setGroupedServices(groupedServices);
      } catch (e) {
        console.log(`Unable to fetch services: ${e.message}`);
        notifications.addNotification("Unable to fetch services", "error");
      }
    }
    Promise.all([loadCategories(), loadServices()]);
  }, []);

  useEffect(() => {
    if (selectedServiceCategory) {
      const lst = groupedServices[selectedServiceCategory.label];
      if (lst) {
        setServices(lst);
      }
    }
  }, [selectedServiceCategory, groupedServices]);

  useEffect(() => {
    setLoading(true);
    const initializeForm = async () => {
      try {
        // Disable form if we are in view mode
        if (appointmentID && userRoles.includes("patient")) {
          setFormDisabled(true);
          console.log("form disabled");
        }
        await Promise.all([loadPatients(), loadDoctors()]);
        await loadAppointmentDetails();
      } catch (err) {
        setFatalError(true);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeForm();
  }, []);

  // Select a specific caregiver if the id has been specified. Only works for
  // patient and admin roles since a caregiver can only view his/her details.
  useEffect(() => {
    if (caregiverID && !appointmentID && (userRoles.includes("patient") || userRoles.includes("admin"))) {
      const details = doctors.get(caregiverID);
      if (details) {
        setSelectedDoctor({
          value: caregiverID,
          label: details.name
        })
      }
    }
  }, [caregiverID, doctors]);

  // Select a specific patient if the id has been specified.
  useEffect(() => {
    if (patientID && !appointmentID && (userRoles.includes("patient") || userRoles.includes("admin"))) {
      const details = patients.get(patientID);
      if (details) {
        setSelectedPatient({
          value: patientID,
          label: details.name
        })
      }
    }
  }, [patientID, patients]);

  // Autofill the patient biodata fields with data from the API
  useEffect(() => {
    // Get full patient details
    if (selectedPatient && !userRoles.includes("caregiver")) {
      const patientDetails = patients.get(selectedPatient.value);
      if (patientDetails) {
        setPatientEmail(patientDetails.email);
        setPatientAddress(patientDetails.location);
        setPatientMobile(patientDetails.phone_number);
        setPatientBio(patientDetails.bio);
        setPatientGender(patientDetails.gender);
      } else {
        setError("Patient details not found");
      }
    }
  }, [selectedPatient]);

  // Autofill the caregiver biodata fields with data from the API
  useEffect(() => {
    if (selectedDoctor) {
      const doctorDetails = doctors.get(selectedDoctor.value);
      if (doctorDetails) {
        setDoctorAddress(doctorDetails.location);
        setDoctorBio(doctorDetails.bio);
        setDoctorMobile(doctorDetails.phone_number);
      } else {
        setError("Healthcare staff details not found");
      }
    }
  }, [selectedDoctor]);


  return (
    <>
      {
        fatalError ? <Banner type={"error"} text={error} dismissible={false}/> :
          <form onSubmit={handleOnSubmit}>
            {
              loading ? <SpinAnimation/> : <div className="row">
                <div className="col-12">
                  <div className="form-heading">
                    <h4>Patient Details</h4>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Patient Name<span className="login-danger">*</span>
                    </label>
                    <Select
                      value={selectedPatient}
                      isDisabled={formDisabled}
                      onChange={setSelectedPatient}
                      required
                      options={patientNames}
                      menuPortalTarget={document.body}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null
                      }}

                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                          },
                          borderRadius: '10px',
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: '250ms',
                          width: '35px',
                          height: '35px',
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group select-gender">
                    <label className="gen-label">
                      Gender
                    </label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          disabled
                          name="gender"
                          value="Male"
                          className="form-check-input"
                          checked={patientGender === 'Male'}
                          onChange={(e) => {
                            setPatientGender(e.target.value)
                          }}
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          disabled
                          className="form-check-input"
                          checked={patientGender === 'Female'}
                          onChange={(e) => {
                            setPatientGender(e.target.value)
                          }}
                        />
                        Female
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Mobile
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={patientMobile}
                      disabled
                      onChange={(e) => patientMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      value={patientEmail}
                      disabled
                      onChange={(e) => setPatientEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      ID Number
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={patientIDNumber}
                      disabled
                      onChange={(e) => setPatientIDNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Address
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={patientAddress}
                      disabled
                      onChange={(e) => setPatientAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-10">
                  <div className="form-group local-forms">
                    <label>
                      Patient Bio
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      cols={30}
                      disabled
                      value={patientBio || ""}
                      onChange={(e) => setPatientBio(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-heading">
                    <h4>Appointment Details</h4>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group local-forms cal-icon">
                    <label>
                      Date of Appointment{" "}
                      <span className="login-danger">*</span>
                    </label>
                    <DatePicker
                      className="form-control datetimepicker"
                      disabled={formDisabled}
                      value={date ? dayjs(date, 'YYYY-MM-DD') : ""}
                      onChange={onDateChange}
                      suffixIcon={null}
                      style={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: isClicked ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : 'none',
                          },
                        })
                      }}
                    />
                    {/* <input
                        className="form-control datetimepicker"
                        type="text"
                      /> */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group local-forms">
                    <label>
                      From <span className="login-danger">*</span>
                    </label>
                    <div className="">
                      <TextField
                        className="form-control"
                        id="outlined-controlled"
                        disabled={formDisabled}
                        type="time"
                        required
                        value={startTime}
                        onChange={(event) => {
                          setStartTime(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group local-forms">
                    <label>
                      To <span className="login-danger">*</span>
                    </label>
                    <div className="">
                      <TextField
                        className="form-control"
                        id="outlined-controlled"
                        disabled={formDisabled}
                        type="time"
                        required
                        value={endTime}
                        onChange={(event) => {
                          setEndTime(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Service Category<span className="login-danger">*</span>
                    </label>
                    <Select
                      value={selectedServiceCategory}
                      isDisabled={formDisabled}
                      onChange={setSelectedServiceCategory}
                      required
                      options={serviceCategories}
                      menuPortalTarget={document.body}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null
                      }}

                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                          },
                          borderRadius: '10px',
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: '250ms',
                          width: '35px',
                          height: '35px',
                        }),
                      }}
                    />
                  </div>
                </div>
                {
                  (selectedServiceCategory || selectedService) ?
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Service<span className="login-danger">*</span>
                        </label>
                        <Select
                          value={selectedService}
                          isDisabled={formDisabled}
                          onChange={setSelectedService}
                          required
                          options={services}
                          menuPortalTarget={document.body}
                          id="search-commodity"
                          components={{
                            IndicatorSeparator: () => null
                          }}

                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                              boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                              '&:hover': {
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                              },
                              borderRadius: '10px',
                              fontSize: "14px",
                              minHeight: "45px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                              transition: '250ms',
                              width: '35px',
                              height: '35px',
                            }),
                          }}
                        />
                      </div>
                    </div> : null
                }
                <div className="col-12 col-sm-10">
                  <div className="form-group local-forms">
                    <label>
                      Additional Notes
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Include an optional note to the healthcare provider with any additional details."
                      rows={3}
                      cols={30}
                      disabled={formDisabled}
                      value={appointmentDescription || ""}
                      onChange={(e) => setAppointmentDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Healthcare Provider Name<span className="login-danger">*</span>
                    </label>
                    <Select
                      value={selectedDoctor}
                      onChange={setSelectedDoctor}
                      isDisabled={formDisabled}
                      options={doctorNames}
                      required
                      menuPortalTarget={document.body}
                      styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null
                      }}

                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                          '&:hover': {
                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                          },
                          borderRadius: '10px',
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: '250ms',
                          width: '35px',
                          height: '35px',
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6" hidden={userRoles.includes("patient")}>
                  <div className="form-group local-forms">
                    <label>
                      Healthcare Provider Address <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={doctorAddress}
                      onChange={(e) => setDoctorAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Healthcare Provider Mobile
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={doctorMobile}
                      disabled
                      onChange={(e) => setDoctorMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-10">
                  <div className="form-group local-forms">
                    <label>
                      Healthcare Provider Bio
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      cols={30}
                      disabled
                      value={doctorBio || ""}
                      onChange={(e) => setDoctorBio(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="doctor-submit text-end">
                    <button
                      type="submit"
                      hidden={formDisabled}
                      className="btn btn-primary submit-form me-2"
                    >
                      Submit
                    </button>
                    <button
                      type="reset"
                      onClick={() => goBack()}
                      className="btn btn-primary cancel-form"
                    >
                      {formDisabled ? "Go back" : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            }
          </form>
      }
    </>
  );
};

AddAppointmentForm.propTypes = {
  appointmentID: PropTypes.string,
  caregiverID: PropTypes.string,
  patientID: PropTypes.string,
  appointmentDate: PropTypes.string,
}

export default AddAppointmentForm;
