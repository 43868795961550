/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/AuthProvider";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import useErrorNotification from "../../hooks/useErrorNotification";
import {putData} from "../../api/putData";
import {postData} from "../../api/postData";
import {useNotifications} from "../../hooks/NotificationProvider";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import {fetchData} from "../../api/fetchData";

export default function AddNextOfKinForm({id, patientID}) {
  const {token} = useAuth();
  const notifications = useNotifications();
  const {goBack} = useCustomNavigation();

  const {userRoles, user} = useAuth();
  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState("");
  const [loading, setLoading] = useState(false);

  // Form props
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [relationship, setRelationship] = useState("");

  useErrorNotification(error);

  async function onSubmit(event) {
    setLoading(true);
    event.preventDefault();

    if (event.target.checkValidity) {
      const formData = {
        name,
        email: email || null,
        phone_number: mobile,
        relationship,
        patient_id: patientID
      }

      try {
        if (id) {
          await putData(`next-of-kin/${id}`, token, formData);
          notifications.addNotification("Details updated successfully", "success");
          goBack();
        } else {
          await postData('next-of-kin', token, formData);
          notifications.addNotification("Next of kin added successfully", "success");
          goBack();
        }
      } catch (err) {
        setError(err.message);
      }
    }

    setLoading(false);
  }

  // Check for errors in the parameters
  useEffect(() => {
    if (!patientID) {
      setFatalError("The query parameter 'patientID' is required");
    } else if (!(userRoles.includes("admin") || user.id === patientID)) {
      setFatalError("You do not have enough permission to perform the requested action");
    }
  }, []);

  // Load item data from the API if id has been provided
  useEffect(() => {
    const populateForm = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`next-of-kin/${id}`, token);
        setName(data.name || "");
        setEmail(data.email || "");
        setRelationship(data.relationship || "");
        setMobile(data.phone_number || "");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id && !fatalError) {
      populateForm().then(() => {
        console.log(`Next of kin record loaded successfully`);
      });
    }
  }, [id]);

  return (
    <>
      {
        fatalError ? <Banner text={fatalError} type={"error"} dismissible={false}/> :
          <form onSubmit={onSubmit}>
            {
              loading ? <SpinAnimation/> :
                <div className="row">
                  <div className="col-12">
                    <div className="form-heading">
                      <h4>Next of Kin Details</h4>
                    </div>
                  </div>

                  {/* Name */}
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Full names<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Mobile */}
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Phone Number<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={mobile}
                        required
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        E-mail
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Relationship */}
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Relationship<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="e.g. Brother"
                        value={relationship}
                        required
                        onChange={(e) => setRelationship(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="doctor-submit text-end">
                      <button
                        type="submit"
                        className="btn btn-primary submit-form me-2"
                      >
                        Submit
                      </button>
                      <button
                        type={"reset"}
                        onClick={() => goBack()}
                        className="btn btn-primary cancel-form"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
            }
          </form>
      }
    </>
  );
}

AddNextOfKinForm.propTypes = {
  patientID: PropTypes.string,
  id: PropTypes.string,
}