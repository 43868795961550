import React from "react";

const PatientReportNotes = () => {


  return (
    <>
      <div className="col-lg-8">
        <div className="card-box">
          <h4 className="card-title">Patient Report Notes for Appointment #123</h4>
          <form action="#">
            <div className="form-group row">
              <label className="col-form-label col-md-2">Type of Note</label>
              <div className="col-md-10">
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="note-type" value="Observation"/> Observation
                  </label>
                </div>
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="note-type" value="Follow Up"/> Follow Up
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-2">
                Notes
              </label>
              <div className="col-md-10">
                        <textarea
                          rows={5}
                          cols={5}
                          className="form-control"
                          placeholder="Enter text here"
                          defaultValue={""}
                        />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-2">Level of Severity</label>
              <div className="col-md-10">
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="severity" value="Normal"/> Normal
                  </label>
                </div>
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="severity" value="Moderate"/> Moderate
                  </label>
                </div>
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="severity" value="Severe"/> Severe
                  </label>
                </div>
                <div className="radio">
                  <label className="col-form-label col-md-2">
                    <input type="radio" name="severity" value="Critical"/> Critical
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-2">
                Recommendation
              </label>
              <div className="col-md-10">
                        <textarea
                          rows={3}
                          cols={5}
                          className="form-control"
                          placeholder="Enter text here"
                          defaultValue={""}
                        />
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
};

export default PatientReportNotes;
